import Grid from '@mui/material/Unstable_Grid2';

import DataDisplayer from '../../components/DataDisplayer';

function Households({ link }) {

    return (
        <Grid container direction="column" rowSpacing={2}>
            <Grid>
                <h2>Households</h2>
            </Grid>
            <DataDisplayer link={`${link}/households`} itemLinkBase="/dashboard/household" type="household" dataKey="accounts" countKey="count" />
        </Grid>
    );

};

export default Households;