export const con = [
    // {
    //     type: "radiogroup",
    //     name: "COPDEV_A",
    //     title: "Have you EVER been told by a doctor or other health professional that you had Chronic Obstructive Pulmonary Disease (COPD), emphysema, or chronic bronchitis?",
    //     description: "Pulmonary disease that is characterized by chronic airway obstruction resulting in a slowed rate of exhalation. Emphysema is a condition of the lung marked by abnormal enlargement of the alveoli with loss of pulmonary elasticity that is characterized especially by shortness of breath and may lead to impairment of heart action. Chronic bronchitis is a chronic inflammation of the bronchial tubes",
    //     isRequired: false,
    //     colCount: 2,
    //     choices: [
    //         { value: "1", text: "Yes" },
    //         { value: "2", text: "No" },
    //         { value: "7", text: "Refused" },
    //         { value: "9", text: "Don't Know" }
    //     ],
    //     visibleIf: "true", // Assuming respondents are adults (18+)
    //     // Skip logic can be implemented here if needed.
    // },
    // {
    //     type: "radiogroup",
    //     name: "ARTHEV_A",
    //     title: "Have you EVER been told by a doctor or other health professional that you had some form of arthritis, rheumatoid arthritis, gout, lupus, or fibromyalgia?",
    //     description: "Rheumatoid arthritis is a usually chronic autoimmune disease that is characterized especially by pain, stiffness, inflammation, swelling, and sometimes destruction of joints. Lupus is an autoimmune disease that affects multiple organs and can cause inflammation and tissue damage. Fibromyalgia is a condition that causes widespread pain, fatigue, and emotional distress.",
    //     isRequired: false,
    //     colCount: 2,
    //     choices: [
    //         { value: "1", text: "Yes" },
    //         { value: "2", text: "No" },
    //         { value: "7", text: "Refused" },
    //         { value: "9", text: "Don't Know" }
    //     ],
    //     visibleIf: "true", // Assuming respondents are adults (18+)
    //     // Skip logic can be implemented here if needed.
    // },
    // {
    //     type: "radiogroup",
    //     name: "DEMENEV_A",
    //     title: "Have you EVER been told by a doctor or other health professional that you had dementia, including Alzheimer's disease?",
    //     isRequired: false,
    //     colCount: 2,
    //     choices: [
    //         { value: "1", text: "Yes" },
    //         { value: "2", text: "No" },
    //         { value: "7", text: "Refused" },
    //         { value: "9", text: "Don't Know" }
    //     ],
    //     visibleIf: "true", // Assuming respondents are adults (18+)
    //     // Skip logic can be implemented here if needed.
    // },
    // {
    //     type: "radiogroup",
    //     name: "ANXEV_A",
    //     title: "Have you EVER been told by a doctor or other health professional that you had any type of anxiety disorder?",
    //     isRequired: false,
    //     colCount: 2,
    //     choices: [
    //         { value: "1", text: "Yes" },
    //         { value: "2", text: "No" },
    //         { value: "7", text: "Refused" },
    //         { value: "9", text: "Don't Know" }
    //     ],
    //     visibleIf: "true", // Assuming respondents are adults (18+)
    //     // Skip logic can be implemented here if needed.
    // },
    // {
    //     type: "radiogroup",
    //     name: "DEPEV_A",
    //     title: "Have you EVER been told by a doctor or other health professional that you had any type of depression?",
    //     isRequired: false,
    //     colCount: 2,
    //     choices: [
    //         { value: "1", text: "Yes" },
    //         { value: "2", text: "No" },
    //         { value: "7", text: "Refused" },
    //         { value: "9", text: "Don't Know" }
    //     ],
    //     visibleIf: "true", // Assuming respondents are adults (18+)
    //     // Skip logic can be implemented here if needed.
    // },
    {
        type: "checkbox",
        name: "CONISSUES_A",
        title: "Have you EVER been told by a doctor or other health professional that you had: (SELECT ALL THAT APPLIES)",
        isRequired: false,
        enabledChoices: true,
        colCount: 2,
        choices: [
            { value: "1", text: "No" },
            { value: "2", text: "Arthritis" },
            { value: "3", text: "Rheumatoid Arthritis" },
            { value: "4", text: "Lupus" },
            { value: "5", text: "Fibromyalgia" },
            { value: "7", text: "Refused" },
            { value: "9", text: "Don't Know" }
        ],
        visibleIf: "true"
    },
    {
        type: "radiogroup",
        name: "GOUTCONSENT_A",
        title: "Do you consent to participate in the Gout Study?",
        isRequired: false,
        colCount: 2,
        choices: [
            { value: "1", text: "Yes" },
            { value: "2", text: "No" }
        ],
        visibleIf: "true", // Assuming respondents are adults (18+)
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GOUTHEALTHPRO_A",
        title: "Have you EVER been told by a doctor or other health professional that you had gout?",
        isRequired: false,
        colCount: 2,
        choices: [
            { value: "1", text: "Yes" },
            { value: "2", text: "No" },
            { value: "7", text: "Refused" },
            { value: "9", text: "Don't Know" }
        ],
        visibleIf: "true"
    },
    {
        type: "radiogroup",
        name: "GOUTPRESCRIBE_A",
        title: "Have you been prescribed medication to treat your gout?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{GOUTCONSENT_A} == 1", // Assuming 'AGE_A' represents age variable
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "GOUTMED_A",
        title: "How often do you take your medication for gout?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Daily"
            },
            {
                value: 2,
                text: "Weekly"
            },
            {
                value: 3,
                text: "Monthly"
            },
            {
                value: 4,
                text: "A few times a year"
            },
            {
                value: 5,
                text: "Never"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{GOUTPRESCRIBE_A} == 1", // Assuming 'AGE_A' represents age variable
        // Skip logic can be implemented here if needed.
    },
    {
        type: "text",
        name: "GOUTTYPEMED_A",
        title: "What type of medication?",
        inputType: "text",
        placeHolder: "Enter name of medication. 97 for Refused and 99 for Other.",
        isRequired: false,
        validators: [
            {
                type: "text"
            }
        ],
        visibleIf: "{GOUTPRESCRIBE_A} == 1"
    },
    {
        type: "radiogroup",
        name: "GOUTFAM_A",
        title: "Does anyone in your immediate family have gout?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'AGE_A' represents age variable
        // Skip logic can be implemented here if needed.
    },
    {
        type: "text",
        inputType: "number",
        name: "GOUTCOUNT_A",
        title: "How many people in your immediate family have been diagnosed with gout?",
        isRequired: false,
        placeHolder: "Enter a number between 0 and 30",
        requiredErrorText: "Please enter a valid number.",
        validators: [
            {
                type: "numeric",
                minValue: 0,
                maxValue: 30,
                text: "Please enter a number between 0 and 30."
            }
        ],
        visibleIf: "{GOUTFAM_A} == 1", // Assuming 'GOUTFAM_A' represents the response to the previous question
        // Skip logic can be implemented here if needed.
    }
];