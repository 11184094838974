import { TextField, Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

import useSWR from "swr";
import Loading from "./Loading";

function Auth({ link, title }) {

    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const twoFactorRef = useRef(null);
    const navigate = useNavigate();
    const [ page, setPage ] = useState("login");
    const [ twoFactor, setTwoFactor ] = useState("");


    async function fetcher() {

        if (page === "verify") {

            const verify2FAReq = await fetch(`${link}/2fa`, 
            {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }, 
            credentials: "include",
            body: JSON.stringify({
                code: twoFactorRef.current.value
            })});

            const verify2FA = await verify2FAReq.json();

            return { verify2FA }

        }


        const loginReq = await fetch(`${link}/login`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                email: emailRef.current.value,
                password: passwordRef.current.value
            })
        });

        const login = await loginReq.json();

        return { login }

    };

    const { data, error, isLoading, mutate } = useSWR("/login", fetcher, { revalidateOnMount: false, revalidateOnFocus: false, revalidateOnReconnect: false, revalidateIfStale: false });

    useEffect(() => {

        if (data?.login?.requiresTwoFactorAuth) {

            setPage("verify");

        }

        if (data?.login?.error || data?.verify2FA?.error) {

            setPage("login");

        }
        

    }, [data])

    if (error) {

        return <Loading />;

    };

    if (data) {

        if (data.hasOwnProperty("login")) {

            if (data.login.hasOwnProperty("message")) {

                if (data.login.message === "Login Successful.") {
                    
                    return navigate("/dashboard");

                } 

            }

            if (data.login?.error) {

                console.log("error occurred!");

            }

        }

        if (data.hasOwnProperty("verify2FA")) {

            if (data?.verify2FA?.hasOwnProperty("message")) {

                if (data?.verify2FA?.message === "Login Successful.") {

                    return navigate("/dashboard");

                } 
    

            }

            if (data.verify2FA?.error) {

                console.log("error");

            }

        }

    };

    return (
        <form>
            <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center" >
                    <Grid>
                        <h1 style={{textAlign: "center"}}>{title}</h1>
                    </Grid>
                    { !data?.login?.requiresTwoFactorAuth ? <><Grid>
                        { data?.login?.error && !isLoading ? <p style={{textAlign: "center", color: "red"}}>Invalid Credentials.</p> 
                        : 
                        data?.verify2FA?.error && !isLoading ?
                        <p style={{textAlign: "center", color: "red"}}>Invalid 2FA Code. Please re-login to try again.</p>
                        :
                        <p style={{textAlign: "center"}}>Enter your credentials.</p>}
                    </Grid>
                    <Grid>
                        <TextField variant="outlined" id="email" label="Email" type="email" style={{width: "100%"}} inputRef={emailRef} />
                    </Grid>
                    <Grid>
                        <TextField variant="outlined" id="password" label="Password" type="password" style={{width: "100%"}} inputRef={passwordRef} />
                    </Grid></>
                    : <>
                    <Grid>
                        <p style={{textAlign: "center"}}>This account has two factor authentication enabled. Please check your email for the code.</p>
                    </Grid>
                    <Grid>
                        <TextField placeholder="Your code." defaultValue="Your code." variant="outlined" id="Code" label="Code" type="text" style={{width: "100%"}} inputRef={twoFactorRef} value={twoFactor} onChange={(e) => {setTwoFactor(e.target.value)}}/>
                    </Grid>
                    </>

                    }
                    <Grid>
                        { !data?.login?.requiresTwoFactorAuth ? 
                        <Button variant="contained" type="submit" onClick={(e) => {
                            e.preventDefault();
                            mutate();
                        }}>{ isLoading ? "Loading..." : "Log In"}</Button>
                        :
                        <Button variant="contained" type="submit" onClick={async (e) => {
                            e.preventDefault();
                            mutate();
                        }}>{ isLoading ? "Loading...." : "Submit Code"}</Button>
                    }
                    </Grid>
            </Grid>
        </form>
    );

};

export default Auth;