import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import useSWR from "swr";
import Loading from '../../components/Loading';

import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import ModuleButton from '../../components/ModuleButton';

import { useEffect, useState } from "react";
import { constants } from '../../utils/constants';

import AccountActions from '../../components/AccountActions';
import AccountProfile from '../../components/AccountProfile';

import Error from '../Error';

import Paper from "@mui/material/Paper";

function Surveyee({ link }) {

    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false);};

    const [ sendNotification ] = useOutletContext();

    const importsArray = [
        { type : "chl", ageGroup: constants.ADULT_TYPE },
        { type : "cvc", ageGroup: constants.ADULT_TYPE },
        { type : "his", ageGroup: constants.BOTH_TYPE },
        { type : "hyp", ageGroup: constants.ADULT_TYPE },
        { type : "lsf", ageGroup: constants.BOTH_TYPE },
        { type : "ast", ageGroup: constants.CHILD_TYPE },
        { type : "can", ageGroup: constants.ADULT_TYPE },
        { type : "dib", ageGroup: constants.BOTH_TYPE },
        { type : "con", ageGroup: constants.ADULT_TYPE },
        { type : "epi", ageGroup: constants.ADULT_TYPE },
        { type : "bmi", ageGroup: constants.BOTH_TYPE },
        { type : "vis", ageGroup: constants.BOTH_TYPE },
        { type : "hea", ageGroup: constants.BOTH_TYPE },
        { type : "cog", ageGroup: constants.BOTH_TYPE },
        { type : "upp", ageGroup: constants.BOTH_TYPE },
        { type : "ins", ageGroup: constants.BOTH_TYPE },
        { type : "pay", ageGroup: constants.BOTH_TYPE },
        { type : "cvl", ageGroup: constants.BOTH_TYPE },
        { type : "dnc", ageGroup: constants.BOTH_TYPE },
        { type : "utz", ageGroup: constants.BOTH_TYPE },
        { type : "abh", ageGroup: constants.ADULT_TYPE },
        { type : "tlh", ageGroup: constants.BOTH_TYPE },
        { type : "pmd", ageGroup: constants.BOTH_TYPE },
        { type : "ptc", ageGroup: constants.BOTH_TYPE },
        { type : "anx", ageGroup: constants.BOTH_TYPE },
        { type : "dep", ageGroup: constants.CHILD_TYPE },
        { type : "mhc", ageGroup: constants.BOTH_TYPE },
        { type : "phq", ageGroup: constants.ADULT_TYPE },
        { type : "gad", ageGroup: constants.ADULT_TYPE },
        { type : "cig", ageGroup: constants.ADULT_TYPE },
        { type : "otb", ageGroup: constants.ADULT_TYPE },
        { type : "aqs", ageGroup: constants.ADULT_TYPE },
        { type : "phy", ageGroup: constants.BOTH_TYPE },
        { type : "wlk", ageGroup: constants.ADULT_TYPE },
        { type : "cpa", ageGroup: constants.ADULT_TYPE },
        { type : "slp", ageGroup: constants.BOTH_TYPE },
        { type : "dnu", ageGroup: constants.ADULT_TYPE },
        { type : "cih", ageGroup: constants.BOTH_TYPE },
        { type : "gni", ageGroup: constants.ADULT_TYPE },
        { type : "mar", ageGroup: constants.ADULT_TYPE },
        { type : "vet", ageGroup: constants.ADULT_TYPE },
        { type : "nat", ageGroup: constants.BOTH_TYPE },
        { type : "sch", ageGroup: constants.BOTH_TYPE },
        { type : "emp", ageGroup: constants.ADULT_TYPE },
        { type : "inc", ageGroup: constants.ADULT_TYPE }, // needs universe logic
        { type : "foo", ageGroup: constants.BOTH_TYPE }, // needs universe logic
        { type : "fds", ageGroup: constants.BOTH_TYPE },
        { type : "hou", ageGroup: constants.BOTH_TYPE },
        { type : "tbh", ageGroup: constants.ADULT_TYPE },
        { type : "tel", ageGroup: constants.BOTH_TYPE },
        { type : "dld", ageGroup: constants.CHILD_TYPE },
        { type : "ims", ageGroup: constants.BOTH_TYPE },
        { type : "cvv", ageGroup: constants.CHILD_TYPE },
        { type : "hpv", ageGroup: constants.CHILD_TYPE },
        { type : "sdq", ageGroup: constants.CHILD_TYPE },
        { type : "sle", ageGroup: constants.CHILD_TYPE },
        { type : "bly", ageGroup: constants.CHILD_TYPE },
        { type : "tbi", ageGroup: constants.CHILD_TYPE },
        { type : "nhc", ageGroup: constants.CHILD_TYPE },
        { type : "scr", ageGroup: constants.CHILD_TYPE },
        { type : "fem", ageGroup: constants.BOTH_TYPE },
        { type: "misc", ageGroup: constants.BOTH_TYPE }
      ];

      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const [ completedModules, setCompletedModules ] = useState([]);


    async function fetcher() {

        const accountURL = `${link}/patient/${id}`;

        const accountReq = await fetch(accountURL, {
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            method: "GET"
        });

        const surveyee = await accountReq.json();

        if (!surveyee.patient._id) {

            throw new Error("Account does not exist.");

        }

        return { surveyee }


    }

    const { data, error, isLoading } = useSWR(`/a/${id}`, fetcher);

    useEffect(() => {

        setCompletedModules([]);

        if (isLoading === false) {

            data?.surveyee?.patient?.responses?.map((value) => setCompletedModules((prev) => [...prev, value.module]));

        }

    }, [data, isLoading])

    if (isLoading) return <Loading />;
    if (error) return <Error message={"Failed to fetch surveyee. Either it does not exist or the database is offline."}/>;

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Confirmation of Deletion
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure about deleting {data?.surveyee?.patient?.firstName}?
                    </Typography>
                    <ButtonGroup>
                        <Button onClick={async () => {
                                const deletionReq = await fetch(`${constants.BACKEND_ADDRESS}/patient/delete/${data?.surveyee.patient?._id}`, {
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    method: "DELETE",
                                    credentials: "include"
                                });

                                const deletion = await deletionReq.json();

                                if (deletion.notEnoughPermissionsErr) {

                                    return navigate("/dashboard/error", { state: { navMessage: "Action failed. You do not have enough permissions to perform this action." }});

                                }

                                sendNotification("Successfully deleted surveyee.");
                                return navigate("/dashboard/surveyees")}}
                                >Yes</Button>
                        <Button onClick={handleClose}>No</Button>
                    </ButtonGroup>
                </Grid>
            </Modal>
            <Grid container direction="column" rowSpacing={2}>
                <Grid>
                    <Typography variant="h3" component="h1" sx={{ fontWeight: "bolder" }}>
                        {data?.surveyee?.patient?.firstName.toUpperCase()} {data?.surveyee?.patient?.lastName ? data?.surveyee?.patient?.lastName.toUpperCase() : null}
                    </Typography>
                    <Typography variant="p" component="h2" sx={{ fontSize: "1rem", opacity: "0.5" }} >
                        { data?.surveyee?.patient?.age >= 18 ? "ADULT" : data?.surveyee?.patient?.age < 18 ? "CHILD" : null } SURVEYEE
                    </Typography>
                </Grid>
                <Grid> 
                    <Paper elevation={3}>
                        <AccountProfile id={data?.surveyee?.patient?._id}  
                                    firstName={data?.surveyee?.patient?.firstName}
                                    lastName={data?.surveyee?.patient?.lastName}
                                    age={data?.surveyee?.patient?.age}
                                    createdAt={data?.surveyee?.patient?.createdAt}
                                    updatedAt={data?.surveyee?.patient?.updatedAt}
                                    ethnicity={data?.surveyee?.patient?.ethnicity}
                                    comment={data?.surveyee?.patient?.comment}
                        />
                    </Paper>
                </Grid>
                <Grid>
                <AccountActions id={data?.surveyee?.patient?._id} type="surveyee" 
                prefilledData={{firstName: data?.surveyee?.patient?.firstName,
                                lastName: data?.surveyee?.patient?.lastName,
                                gender: data?.surveyee?.patient?.gender,
                                race: data?.surveyee?.patient?.race,
                                age: data?.surveyee?.patient?.age,
                                ethnicity: data?.surveyee?.patient?.ethnicity,
                                comment: data?.surveyee?.patient?.comment}}
                onDelete={handleOpen}
                />
                </Grid>
                <Grid>
                    <Typography variant="h4" component="h2" color="#1976D2" sx={{ fontWeight: "bolder" }}>
                        Modules
                    </Typography>
                </Grid>
                <Grid>
                    <Typography variant="h5" component="h3" sx={{ fontWeight: "bolder", opacity: 0.5 }}>
                        Completed Modules
                    </Typography>
                </Grid>
                <Grid container justifyContent={"space-around"}>
                        { completedModules.length > 0 ? completedModules.map((value) => {
                            if (value.length < 1) return null;
                            return <Grid xs={12} md={4} lg={3}><ModuleButton key={value} isCompleted={true} moduleName={value} link={`/dashboard/surveyee/${id}/survey/${value}`} /></Grid>
                        }) : "No completed modules"
                        }
                </Grid>
                <Grid>
                    <Typography variant="h5" component="h3" sx={{ fontWeight: "bolder", opacity: 0.5 }}>
                        Incomplete Modules
                    </Typography>
                </Grid>
                <Grid container justifyContent={"space-around"}>
                        {
                        importsArray.map((value) => {
                            if (completedModules.includes(value.type)) return null;
                            if (parseInt(data?.surveyee?.patient?.age) >= 18 && value.ageGroup === constants.ADULT_TYPE) return <Grid xs={12} md={4} lg={3}><ModuleButton key={value.type} isCompleted={false} moduleName={value.type} link={`/dashboard/surveyee/${id}/survey/${value.type}`} /></Grid>
                            if (parseInt(data?.surveyee?.patient?.age) <= 17 && value.ageGroup === constants.CHILD_TYPE) return <Grid xs={12} md={4} lg={3}><ModuleButton key={value.type} isCompleted={false} moduleName={value.type} link={`/dashboard/surveyee/${id}/survey/${value.type}`} /></Grid>
                            if (value.ageGroup === constants.BOTH_TYPE) return <Grid xs={12} md={4} lg={3}><ModuleButton key={value.type} isCompleted={false} moduleName={value.type} link={`/dashboard/surveyee/${id}/survey/${value.type}`} /></Grid>
                        })
                        }
                </Grid>
            </Grid>
        </>

    );

};

export default Surveyee;