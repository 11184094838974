import ListItem from '@mui/material/ListItem';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import HomeIcon from '@mui/icons-material/Home';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

import { useState, useEffect } from "react";
import { Link } from "react-router-dom"

function CustomListItem({ icon, title, text, link }) {

    const [ avatarIcon, setAvatarIcon ] = useState(<HomeIcon />);

    useEffect(() => {

        switch (icon) {
            case "household":
                setAvatarIcon(<FamilyRestroomIcon />);
            default:
                setAvatarIcon(<HomeIcon />);
        }
    

    }, []);

    return (
        <Link to={link}>
            <ListItem>
                <Card sx={{width: "100%"}}>
                    <CardHeader avatar={avatarIcon} title={title} />
                    { text ? <CardContent>{text}</CardContent> : null }
                </Card>
            </ListItem>
        </Link>
    );

};

export default CustomListItem;