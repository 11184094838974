import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ReceiptIcon from '@mui/icons-material/Receipt';

import NavigationItem from './NavigationItem';
import Profile from './Profile';

function Navigation({ anchor, toggleDrawer, open, username }) {

    return (

        <>
            <SwipeableDrawer anchor={anchor} open={open} onOpen={toggleDrawer} onClose={toggleDrawer}>
                <List sx={{paddingLeft: "2vw", paddingRight: "2vw"}}>
                    <ListItem alignItems="center" sx={{justifyContent: "center"}}>
                        <Profile name={username}/>
                    </ListItem>
                    <NavigationItem title="Home" icon={<HomeIcon />} to={"/dashboard"} />
                    <NavigationItem title="Household" icon={<FamilyRestroomIcon />} to={"/dashboard/households"} />
                    <NavigationItem title="Surveyees" icon={<RecentActorsIcon />} to={"/dashboard/surveyees"} />
                    <NavigationItem title="Account Management" icon={<PersonIcon />} to={"/dashboard/accounts"} />
                    <NavigationItem title="Data Management" icon={<DataObjectIcon />} to={"/dashboard/data"} />
                    <NavigationItem title="Logs" icon={<ReceiptIcon />} to={"/dashboard/logs"} />
                </List>
            </SwipeableDrawer>
        </>

    );


};

export default Navigation;