import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import { useState } from "react";

import PersonIcon from '@mui/icons-material/Person';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import NewSurveyee from './Forms/NewSurveyee';
import NewHousehold from './Forms/NewHousehold';
import NewAccount from './Forms/NewAccount';


function ActionButton({ notifier }) {

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false); setModalType("");};

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

    return <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Create a new { modalType === "surveyee" ? "Surveyee" : modalType === "household" ? "Household" : modalType === "account" ? "Account" : null}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { modalType === "surveyee" ? <NewSurveyee closer={handleClose} notifier={notifier} /> 
            : modalType === "household" ? <NewHousehold closer={handleClose} notifier={notifier} /> 
            : modalType === "account" ? <NewAccount closer={handleClose} notifier={notifier} /> : null}
          </Typography>
        </Grid>
      </Modal>
      <SpeedDial
        ariaLabel="SpeedDial"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction key={1} icon={<RecentActorsIcon />} tooltipTitle={"New Surveyee"} onClick={() => {handleOpen(); setModalType("surveyee")}} />
        <SpeedDialAction key={2} icon={<FamilyRestroomIcon />} tooltipTitle={"New Household"} onClick={() => {handleOpen(); setModalType("household")}} />
        <SpeedDialAction key={3} icon={<PersonIcon />} tooltipTitle={"New Account"} onClick={() => {handleOpen(); setModalType("account")}} />
      </SpeedDial>
    </>

};

export default ActionButton;