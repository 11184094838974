export const misc = [

    {
        type: "radiogroup",
        name: "PCOS_DIAGNOSIS",
        title: "Have you been told by a doctor that you have polycystic ovary syndrome (PCOS)?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Add universe logic if applicable
    },
    {
        type: "text",
        name: "FIRST_TIME_SEX_AGE",
        title: "How old were you when you had sex for the first time (include vaginal, oral, or anal sex) (use code 997 for declaring “Virgin or N/A”) ?",
        inputType: "number",
        isRequired: false,
        placeHolder: "Enter your age",
        validators: [
            {
                type: "numeric",
                minValue: 0,
                maxValue: 999, // Assuming a reasonable maximum age
                text: "Please enter a valid age."
            }
        ],
        // Add relevant visibleIf logic or universe logic if applicable
    },
    {
        type: "radiogroup",
        name: "SEXUALLY_ACTIVE",
        title: "Are you sexually active?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Add universe logic if applicable
    },
    {
        type: "radiogroup",
        name: "SEX_DRUNK_HIGH",
        title: "In the last 12 months, did you have sex while drunk or high (under the influence of alcohol and/or drugs)?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Add universe logic if applicable
    },
    {
        type: "radiogroup",
        name: "PRENATAL_CARE_ACCESS",
        title: "Did you have access to prenatal care during your pregnancy?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes, I had access and used it."
            },
            {
                value: 2,
                text: "Yes, I had access but I did not use it."
            },
            {
                value: 3,
                text: "No, I did not have access."
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{surveyeeSex} == 'F'"
        // Add universe logic if applicable
    },
    {
        type: "radiogroup",
        name: "TRAVEL_FOR_MEDICAL_CARE",
        title: "Have you ever had to travel to seek medical care?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes, I traveled from Guam to another location."
            },
            {
                value: 2,
                text: "Yes, I traveled from another location to Guam."
            },
            {
                value: 3,
                text: "No, I never had to travel for medical care."
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Add universe logic if applicable
    },
    {
        type: "radiogroup",
        name: "TREATMENT_REASON",
        title: "What did you get treated for?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Heart"
            },
            {
                value: 2,
                text: "Diabetes"
            },
            {
                value: 7,
                text: "Other"
            }
        ],
        hasOther: true,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{TRAVEL_FOR_MEDICAL_CARE} == '1' || {TRAVEL_FOR_MEDICAL_CARE} == '2'", // Assuming 'MED_CONDITION' represents a variable indicating medical condition
    },
    {
        type: "radiogroup",
        name: "ELDERLY_CAREGIVER",
        title: "Are you a caregiver for an elderly person 55 years and older?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes, I care for a relative."
            },
            {
                value: 2,
                text: "Yes, I care for someone not related to me."
            },
            {
                value: 3,
                text: "Yes, but through a care-giving company."
            },
            {
                value: 4,
                text: "No."
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Add universe logic if applicable
    },
    {
        type: "radiogroup",
        name: "CANCER_SCREENING_RECOMMENDATION",
        title: "Has a doctor ever recommended that you get screened (tested) for cancer?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes, my doctor recommended it, and I followed up with a referral."
            },
            {
                value: 2,
                text: "Yes, my doctor recommended it, but I did not follow up with a referral."
            },
            {
                value: 3,
                text: "No, my doctor never recommended cancer screening."
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Add universe logic if applicable
    }

];