import Grid from '@mui/material/Unstable_Grid2';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';

import { useState } from "react";
import UpdateAccount from './Forms/UpdateAccount';
import UpdateSurveyee from './Forms/UpdateSurveyee';
import UpdateHousehold from './Forms/UpdateHousehold';

function AccountActions({ id, type, prefilledData, onDelete }) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false);};
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Update { type === "surveyee" ? "Surveyee" : type === "household" ? "Household" : type === "account" ? "Account" : null}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    { type === "account" ? <UpdateAccount id={id} closer={handleClose} prefilledData={prefilledData} />
                    : type === "surveyee" ? <UpdateSurveyee id={id} closer={handleClose} prefilledData={prefilledData} />
                    : type === "household" ? <UpdateHousehold id={id} closer={handleClose} prefilledData={prefilledData} />
                    : null }
                </Typography>
                </Grid>
            </Modal>
            
            <Paper elevation={2} square="false" sx={{paddingTop: "1rem", paddingBottom: "1rem"}}>
                <Typography variant="h6" component="h4" color="#1976D2" sx={{ fontWeight: "bold" }}>Actions</Typography>
                <ButtonGroup>
                    <Button onClick={handleOpen}>Update</Button>
                    <Button onClick={onDelete}>Delete</Button>
                </ButtonGroup>
            </Paper>
        </>
    );


};

export default AccountActions;