import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';

import { Link } from "react-router-dom";

function NavigationItem({ title, icon, to }) {

    return (

        <ListItem sx={{width: "100%"}}>
            <Link to={to}>
                <Button style={{textTransform: "capitalize", width: "inherit"}}>
                    <ListItemIcon>{ icon }</ListItemIcon>
                    <ListItemText>{ title }</ListItemText>
                </Button>
            </Link>
        </ListItem>

    );


};

export default NavigationItem;