import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import BadgeIcon from '@mui/icons-material/Badge';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CakeIcon from '@mui/icons-material/Cake';

import { Link } from "react-router-dom";

function AccountProfile({ id, createdAt, updatedAt, username, role, address, town, scheduledDate, adultSurveyee, childSurveyee, firstName, lastName, gender, age, ethnicity, comment }) {

    return <>
        <Grid container rowSpacing={2} justifyContent="flex-start" alignItems="center" direction="row">

            <Grid xs={12} md={12} lg={12}>

                <Typography variant="h6" component="h3" color="#1976D2" sx={{ fontWeight: "bolder" }}>
                    Account Details
                </Typography>

            </Grid>

            { id ? <Grid xs={12} md={6} lg={6}>
                <BadgeIcon />
                <p>
                    <span style={{fontWeight: "bolder"}}>
                        ID:
                    </span> {id}
                </p>
            </Grid> : null }

            { createdAt ? <Grid xs={12} md={6} lg={6}>
                <CalendarMonthIcon /> 
                <p>
                    <span style={{fontWeight: "bolder"}}>Created:</span> {createdAt}
                </p>
            </Grid> : null }

            { updatedAt ? <Grid xs={12} md={6} lg={6}>
                <CalendarMonthIcon /> 
                <p>
                    <span style={{fontWeight: "bolder"}}>Updated:</span> {updatedAt}
                </p>
            </Grid> : null }

            { username ? <Grid xs={12} md={6} lg={6}>
                <PersonIcon />
                <p>
                <span style={{fontWeight: "bolder"}}>Username:</span> {username}
                </p>
            </Grid> : null }

            { role ? <Grid xs={12} md={6} lg={6}>
                <WorkIcon />
                <p>
                <span style={{fontWeight: "bolder"}}>Role:</span> {role ? role === 0 ? "Viewer" : role === 1 ? "Editor" : role === 2 ? "Admin" : role === 3 ? "Root" : null : null}
                </p>
            </Grid> : null }

            { address ? <Grid xs={12} md={6} lg={6}>
                <ImportContactsIcon />
                <p>
                <span style={{fontWeight: "bolder"}}>Address:</span> {address}
                </p>
            </Grid> : null }

            { town ? <Grid xs={12} md={6} lg={6}>
                <LocationCityIcon />
                <p>
                <span style={{fontWeight: "bolder"}}>Town:</span> {town}
                </p>
            </Grid> : null }

            { scheduledDate ? <Grid xs={12} md={6} lg={6}>
                <CalendarMonthIcon />
                <p>
                    <span style={{fontWeight: "bolder"}}>Scheduled Date:</span> {scheduledDate}
                </p>
            </Grid> : null }

            { adultSurveyee ? <Grid xs={12} md={6} lg={6}>
                <PersonIcon />
                <p>
                    <span style={{fontWeight: "bolder"}}>Adult Surveyee:</span> <Link to={`/dashboard/surveyee/${adultSurveyee}`}>{adultSurveyee}</Link>
                </p>
            </Grid> : null }

            { childSurveyee ? <Grid xs={12} md={6} lg={6}>
                <PersonIcon />
                <p>
                    <span style={{fontWeight: "bolder"}}>Child Surveyee:</span> <Link to={`/dashboard/surveyee/${childSurveyee}`}>{childSurveyee}</Link>
                </p>
            </Grid> : null }

            { firstName ? <Grid xs={12} md={6} lg={6}>
                <PersonIcon />
                <p>
                    <span style={{fontWeight: "bolder"}}>First Name:</span> {firstName}
                </p>
            </Grid> : null }

            { lastName ? <Grid xs={12} md={6} lg={6}>
                <PersonIcon />
                <p>
                    <span style={{fontWeight: "bolder"}}>Last Name:</span> {lastName}
                </p>
            </Grid> : null }

            { gender ? <Grid xs={12} md={6} lg={6}>
                <p>
                    <span style={{fontWeight: "bolder"}}>Gender:</span> {gender}
                </p>
            </Grid> : null }

            { ethnicity ? <Grid xs={12} md={6} lg={6}>
                <PersonIcon />
                <p>
                    <span style={{fontWeight: "bolder"}}>Ethnicity:</span> {ethnicity}
                </p>
            </Grid> : null }

            { age ? <Grid xs={12} md={6} lg={6}>
                <CakeIcon />
                <p>
                    <span style={{fontWeight: "bolder"}}>Age:</span> {age}
                </p>
            </Grid> : null } 

            { comment ? <Grid xs={12} md={6} lg={6}>
                <p>
                    <span style={{fontWeight: "bolder"}}>Comment:</span> {comment}
                </p>
            </Grid> : null
            }

        </Grid>
    </>

};

export default AccountProfile;