import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from "react";
import useSWR from 'swr';

function Data({ link }) {

    const [ age, setAge ]= useState("");
    const [ ageOp, setAgeOp ] = useState("eq");
    const [ sex, setSex ] = useState("");

    console.log(ageOp);

    async function onClickHandler(type) {

        switch (type) {

            case "all-survey-responses-csv":
                const dataReq = await fetch(`${link}/data?exportAs=csv&age=adult`, { credentials: 'include' });
                const data = await dataReq.blob();
                const url = window.URL.createObjectURL(data);
                const linkEl = document.createElement('a');
                linkEl.href = url;
                linkEl.download = "data.csv";
                linkEl.click();
                break;
            case "all-survey-responses-child-csv":
                const dataReqChild = await fetch(`${link}/data?exportAs=csv&age=child`, { credentials: 'include' });
                const dataChild = await dataReqChild.blob();
                const urlChild = window.URL.createObjectURL(dataChild);
                const linkElChild = document.createElement('a');
                linkElChild.href = urlChild;
                linkElChild.download = "data.csv";
                linkElChild.click();
                break;
            case "all-survey-responses-json":
                const dataReqJson = await fetch(`${link}/data?exportAs=json&age=adult`, { credentials: 'include' });
                const dataJson = await dataReqJson.blob();
                const urlJson = window.URL.createObjectURL(dataJson);
                const linkElJson = document.createElement('a');
                linkElJson.href = urlJson;
                linkElJson.download = "data.json";
                linkElJson.click();
                break;
            case "all-survey-responses-child-json":
                const dataReqChildJson = await fetch(`${link}/data?exportAs=json&age=child`, { credentials: 'include' });
                const dataChildJson = await dataReqChildJson.blob();
                const urlChildJson = window.URL.createObjectURL(dataChildJson);
                const linkElChildJson = document.createElement('a');
                linkElChildJson.href = urlChildJson;
                linkElChildJson.download = "data.json";
                linkElChildJson.click();
                break;
            case "custom":
                const dataReqCustom = await fetch(`${link}/data?`, { credentials: "include" });
                const dataCustomJson = await dataReqCustom.blob();
                const urlCustomJson = window.URL.createObjectURL(dataCustomJson);
                const linkElCustomJson = document.createElement('a');
                linkElCustomJson.href = urlCustomJson;
                linkElCustomJson.download = "data.json";
                linkElCustomJson.click();
                break;
            default:
                break;

        }

    }

    return (
        <Grid container direction="column" rowSpacing={2}>
            <Grid>
                <h2>Data Management</h2>
            </Grid>
            <Grid>
                <h3>Data Exporting</h3>
            </Grid>
            <Grid container direction="column">
                <Grid>
                    <h4>Export all Survey Responses</h4>
                    <p>You can export the entire database into a CSV or JSON below.</p>
                    <p>For an excel/sheets-compatible file to be used for analysis, use the CSV option.</p>
                    <p>For quick and performant exporting, use the JSON option.</p>
                </Grid>
                <Grid direction="row">
                    <Button onClick={() => onClickHandler("all-survey-responses-csv")}>Export All Adults as CSV</Button>
                    <Button onClick={() => onClickHandler("all-survey-responses-child-csv")}>Export All Children as CSV</Button>
                    <Button onClick={() => onClickHandler("all-survey-responses-json")}>Export All Adults as JSON</Button>
                    <Button onClick={() => onClickHandler("all-survey-responses-child-json")}>Export All Children as JSON</Button>
                </Grid>
                {/* <Grid>
                    <h3>Custom Export Query</h3>
                    <p>Export a subset of the entire database based on the criteria you've defined.</p>
                </Grid> */}
                {/* <Grid direction="row">
                    <FormControl sx={{width: "25vw"}}>
                        <h4>Age Querying</h4>
                        <p>The unary operator used to define the range of the age below.</p>
                        <RadioGroup
                        aria-labelledby="age-radio-buttons-group"
                        defaultValue="equal"
                        name="age-radio-buttons-group"
                        onChange={(e) => setAgeOp(e.target.value)}
                        >
                            <FormControlLabel value="eq" control={<Radio />} label="Equal" />
                            <FormControlLabel value="gte" control={<Radio />} label="Greater than or Equal" />
                            <FormControlLabel value="lte" control={<Radio />} label="Less than or Equal" />
                            <FormControlLabel value="lt" control={<Radio />} label="Less than" />
                            <FormControlLabel value="gt" control={<Radio />} label="Greater than" />
                        </RadioGroup>
                        <p>The age selector</p>
                        <Select
                            id="age-selector"
                            value={age}
                            label="Age"
                            onChange={(e) => setAge(e.target.value)}
                        >
                            {Array.from({ length: 151 }, (_, i) => i).map((age) => <MenuItem value={age}>{age}</MenuItem>)}
                        </Select>
                        <h4>Sex Querying</h4>
                        <p>Select the sex (gender assigned at birth) to query on.</p>
                        <Select
                            id="sex-selector"
                            value={sex}
                            label="Sex"
                            onChange={(e) => setSex(e.target.value)}
                        >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>Female</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}
            </Grid>
        </Grid>
    );


};

export default Data;