import CircularProgress from '@mui/material/CircularProgress';

function Loading({ wholeScreen }) {

   return <div style={{position: wholeScreen ? "absolute" : "relative", height: wholeScreen ? "100vh" : "100%", width: wholeScreen ? "100vw" : "100%", zIndex: wholeScreen ? "100": "0", opacity: "50", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <CircularProgress />
    </div>

};

export default Loading;