import Grid from '@mui/material/Unstable_Grid2';

import DataDisplayer from '../../components/DataDisplayer';

function Logs({ link }) {

    return (
        <>
            <Grid container direction="column" rowSpacing={2}>
                <Grid>
                    <h2>Logs</h2>
                </Grid>
                <DataDisplayer link={`${link}/logs`} itemLinkBase="/dashboard/log" type="logging" dataKey="logs" countKey="count" />
            </Grid>
        </>
    );

};

export default Logs;