export const slp = [
    {
        type: "text",
        inputType: "number",
        name: "SLPHOURS_A",
        title: "On average, how many hours of sleep do you get in a 24-hour period?",
        description: "Enter hours in whole numbers, rounding 30 minutes (1/2 hour) or more UP to the next whole hour and dropping 29 or fewer minutes.",
        isRequired: false,
        placeHolder: "Enter hours (01-24)",
        validators: [
            {
                type: "numeric",
                minValue: 1,
                maxValue: 24,
                text: "Please enter a number between 01 and 24."
            }
        ],
        requiredErrorText: "Please enter the average number of hours of sleep you get in a 24-hour period.",
        visibleIf: true // Assuming this question is relevant and visible to all Sample Adults 18+
        // Universe logic for NHIS 'Sample Adults 18+' is set to true to make the question visible for all in this demographic.
        // You might need to add skip logic or adjust the visibility conditions based on your specific survey criteria.
    },
    {
        type: "radiogroup",
        name: "SLPREST_A",
        title: "During the past 30 days, how often did you wake up feeling well-rested?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select how often you woke up feeling well-rested in the past 30 days.",
        visibleIf: true // Assuming this question is relevant and visible to all Sample Adults 18+
        // Universe logic for NHIS 'Sample Adults 18+' is set to true to make the question visible for all in this demographic.
        // You might need to add skip logic or adjust the visibility conditions based on your specific survey criteria.
    },
    {
        type: "radiogroup",
        name: "SLPFLL_A",
        title: "During the past 30 days, how often did you have trouble falling asleep?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select how often you had trouble falling asleep in the past 30 days.",
        visibleIf: true // Assuming this question is relevant and visible to all Sample Adults 18+
        // Universe logic for NHIS 'Sample Adults 18+' is set to true to make the question visible for all in this demographic.
        // You might need to add skip logic or adjust the visibility conditions based on your specific survey criteria.
    },
    {
        type: "radiogroup",
        name: "SLPSTY_A",
        title: "During the past 30 days, how often did you have trouble staying asleep, including waking up too early?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select how often you had trouble staying asleep in the past 30 days.",
        visibleIf: true // Assuming this question is relevant and visible to all Sample Adults 18+
        // Universe logic for NHIS 'Sample Adults 18+' is set to true to make the question visible for all in this demographic.
        // You might need to add skip logic or adjust the visibility conditions based on your specific survey criteria.
    },
    {
        type: "radiogroup",
        name: "SLPMED_A",
        title: "During the past 30 days, how often did you take any medication to help you fall asleep or stay asleep? Include both prescribed and over-the-counter medications.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select how often you took medication to help with sleep in the past 30 days.",
        visibleIf: true // Assuming this question is relevant and visible to all Sample Adults 18+
        // Universe logic for NHIS 'Sample Adults 18+' is set to true to make the question visible for all in this demographic.
        // You might need to add skip logic or adjust the visibility conditions based on your specific survey criteria.
    },
    
];

export const slp_c = [

    {
        type: "radiogroup",
        name: "RESTED_C",
        title: "In a typical school week, how often do you wake up well-rested? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'GEN.AGE_FINAL' represents the age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto OUTOFBED_C]
        // If GEN.AGE_FINAL GE 2 and GEN.AGE_FINAL LE 5, fill: "week" If GEN.AGE_FINAL GE 6, fill: "week during the school year"
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    },
    {
        type: "radiogroup",
        name: "OUTOFBED_C",
        title: "In a typical school week, how often do you have difficulty getting out of bed in the morning? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'GEN.AGE_FINAL' represents the age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto TIRED_C]
        // If GEN.AGE_FINAL GE 2 and GEN.AGE_FINAL LE 5, fill: "week" If GEN.AGE_FINAL GE 6, fill: "week during the school year"
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    },
    {
        type: "radiogroup",
        name: "TIRED_C",
        title: "In a typical school week, how often do you complain about being tired during the day? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'GEN.AGE_FINAL' represents the age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto NAPS_C]
        // If GEN.AGE_FINAL GE 2 and GEN.AGE_FINAL LE 5, fill: "week" If GEN.AGE_FINAL GE 6, fill: "week during the school year"
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    },
    {
        type: "radiogroup",
        name: "NAPS_C",
        title: "In a typical school week, how often do you nap or fall asleep during the day, such as in school, watching TV, or riding in a car? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{GEN.AGE_FINAL} >= 2 && {GEN.AGE_FINAL} <= 17", // Assuming 'GEN.AGE_FINAL' represents the age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto BEDTIME_C]
        // If GEN.AGE_FINAL GE 2 and GEN.AGE_FINAL LE 5, fill: "week" If GEN.AGE_FINAL GE 6, fill: "week during the school year"
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    },
    {
        type: "radiogroup",
        name: "BEDTIME_C",
        title: "In a typical school weekN, how often do you go to bed at the same time? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'GEN.AGE_FINAL' represents the age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto WAKETIME_C]
        // If GEN.AGE_FINAL GE 2 and GEN.AGE_FINAL LE 5, fill: "week" If GEN.AGE_FINAL GE 6, fill: "week during the school year, on nights your child had school the next day"
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    },
    {
        type: "radiogroup",
        name: "WAKETIME_C",
        title: "In a typical school week, how often do you wake up at the same time? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'GEN.AGE_FINAL' represents the age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto next section]
        // If GEN.AGE_FINAL GE 2 and GEN.AGE_FINAL LE 5, fill: "week" If GEN.AGE_FINAL GE 6, fill: "week during the school year, on school days"
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    }

];