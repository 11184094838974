import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { Link } from "react-router-dom";

function ModuleButton({ moduleName, isCompleted=false, link }) {

    return <>
        <Link to={link}>
            <Card variant="outlined">
                    <CardContent >
                        <Typography variant="h4" component="h3" sx={{ fontWeight: "bolder" }} >
                            { moduleName.toUpperCase() }
                        </Typography>
                        <Typography variant="h5" component="h4" >
                            { isCompleted ? "Completed" : "Incomplete" }
                        </Typography>
                    </CardContent>
            </Card>
        </Link>
    </>

};

export default ModuleButton;