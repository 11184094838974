import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { constants } from '../../utils/constants';

import { useRef } from "react";

import useSWR from "swr";
import Loading from '../Loading';

function NewAccount({ closer, notifier }) {

    const emailRef = useRef();
    const passwordRef = useRef();
    const usernameRef = useRef();

    const fetcher = async function() {

        const newAccountReq = await fetch(`${constants.BACKEND_ADDRESS}/a/create`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                email: emailRef.current.value,
                password: passwordRef.current.value,
                username: usernameRef.current.value
            })
        });

        const newAccount = await newAccountReq.json();

        if (!newAccount?.err) {
            notifier("Created Account with ID: " + newAccount?.account?._id);
        }
        else {
            notifier("An error occurred while creating an account. Recheck your inputs.");
        }

        return { newAccount }
        

    }

    const { data, isLoading, error, mutate } = useSWR("/api/account/new", fetcher,
    { keepPreviousData: false, revalidateIfStale: false, revalidateOnReconnect: false, revalidateOnMount: false, revalidateOnFocus: false});

    
    const onSubmitHandler = (e) => {

        e.preventDefault();
        mutate();
        closer();

    };

    return (
        <form>
            <FormControl sx={{display: "flex", justifyContent: "space-around", gap: "20px"}}>
                <TextField id="username" inputRef={usernameRef} variant="standard" type="text" label="Username"></TextField>
                <TextField id="email" inputRef={emailRef} variant="standard" type="email" label="Email"></TextField>
                <TextField id="password" inputRef={passwordRef} variant="standard" type="password" label="Password"></TextField>
                <Button type="submit" variant="contained" onClick={onSubmitHandler}>{isLoading ? <Loading /> : "Create Account"}</Button>
            </FormControl>
        </form>
    );


};

export default NewAccount;