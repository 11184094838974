import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchIcon from '@mui/icons-material/Search';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

import CustomListItem from './CustomListItem';

import useSWR from "swr";
import Loading from './Loading';

function DataDisplayer({ link, itemLinkBase, type, dataKey, countKey }) {

    const location = useLocation();
    let [ searchParams, setSearchParams ] = useSearchParams();
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get("page") || '1', 10);
    const [ search, setSearch ] = useState(query.get("search"));
    const mobileDevice = useMediaQuery("(max-width: 600px)");
    const searchRef = useRef(null);

    useEffect(() => {

        mutate();

    }, [])

    useEffect(() => {
        
        if (search) {

            setSearchParams({ search })

        }
        
    }, [search])

    const fetcher = async function() {

        const countingURL = `${link}?count=true${search ? `&search=${search}` : ""}`; 

        // `${link}?count=true`
        const countingLink = await fetch(countingURL, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET",
            credentials: "include"
        });

        const requestURL = `${link}?page=${page}${search ? `&search=${search}` : ""}`;

        // `${link}?page=${page}`
        const requestLink = await fetch(requestURL, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET",
            credentials: "include"
        });

        const count = await countingLink.json();
        const data = await requestLink.json();

        return { count, data };

    }

    const onClickHandler = function() {

        setSearch(searchRef.current.value);

    }

    const { data, error, isLoading, mutate } = useSWR(`/api/data?page=${page}&search=${search}`, fetcher);
    
    if (error) return <h1>An error occurred</h1>;
    if (isLoading) return <Loading />;

    return (

        <>
            <Grid container justifyContent="center" alignItems="center" paddingTop="3vh" paddingBottom="3vh">
                <TextField inputRef={searchRef} placeholder="Search..." sx={{width: mobileDevice ? "75%" :"50%"}} 
                InputProps={
                    {
                        endAdornment: <InputAdornment position="end"><SearchIcon onClick={onClickHandler}/></InputAdornment>
                    }
                } />
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <Pagination count={data ? Math.ceil(data["count"][countKey] / 10) : 1} page={page} variant="outlined" shape="rounded" renderItem={(item) => (
                    <PaginationItem component={Link} to={`${item.page === 1 ? "" : "?page=" + item.page}`} {...item} />
                )
                }/>
            </Grid>
            <Grid>
                <List>
                    { data !== undefined ? data.data?.[dataKey]?.map((item) => 
                    <CustomListItem key={item._id} title={ type === "household" ? item.householdName : type === "surveyee" ? `${item.firstName} ${item.lastName ? item.lastName : ""}` : type === "account" ? item.username : item.accountId} 
                    text={type === "logging" ? item.action : item._id} link={`${itemLinkBase}/${item._id}`} />) : <Loading />}
                </List>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <Pagination count={data ? Math.ceil(data["count"][countKey] / 10) : 1} page={page} variant="outlined" shape="rounded" renderItem={(item) => (
                    <PaginationItem component={Link} to={`${item.page === 1 ? "" : "?page=" + item.page}`} {...item} />
                )
                }/>
            </Grid>
        </>

    );

};

export default DataDisplayer;