import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import FormLabel from '@mui/material/FormLabel';

import { constants } from '../../utils/constants';

import { useRef } from "react";

import useSWR from "swr";

function NewHousehold({ closer, notifier }) {

    const householdNameRef = useRef();
    const householdAddressRef = useRef();
    const householdTownRef = useRef();
    const adultSurveyeeIdRef = useRef();
    const childSurveyeeIdRef = useRef();
    const scheduledDateRef = useRef();

    const fetcher = async function() {

        const body = {
            householdName: householdNameRef.current.value,
            householdAddress: householdAddressRef.current.value,
            householdTown: householdTownRef.current.value,
            adultSurveyee: adultSurveyeeIdRef.current.value,
            childSurveyee: childSurveyeeIdRef.current.value,
            scheduledDate: scheduledDateRef.current.value ? new Date(`${scheduledDateRef.current.value}`).toLocaleDateString("en-US", { timeZone: "Pacific/Port_Moresby" }) : null
        }

        const createHouseholdReq = await fetch(`${constants.BACKEND_ADDRESS}/household/h/new`, {
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify(body)
        });

        const createHousehold = await createHouseholdReq.json();

        if (!createHousehold?.err) {
            notifier("Created Household with ID: " + createHousehold?.household?._id);
        }
        else {
            notifier("An error occurred while creating a surveyee. Recheck your inputs.");
        }

        return { createHousehold };

    }

    const { data, error, isLoading, mutate } = useSWR("/api/surveyee/new", fetcher, 
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false, keepPreviousData: false, revalidateOnMount: false });

    const onSubmitHandler = (e) => {

        e.preventDefault();
        mutate();
        closer();

    };

    return (
        <form>
            <FormControl sx={{display: "flex", justifyContent: "space-around", gap: "20px"}}>
                <TextField id="householdName" inputRef={householdNameRef} label="Household Name" variant="standard" />
                <TextField id="householdAddress" inputRef={householdAddressRef} label="Household Address" variant="standard" />
                <TextField id="householdTown" inputRef={householdTownRef} label="Household Town" variant="standard" />
                <TextField id="householdAdultSurveyee" inputRef={adultSurveyeeIdRef} label="Adult Surveyee" variant="standard" />
                <TextField id="householdChildSurveyee" inputRef={childSurveyeeIdRef} label="Child Surveyee" variant="standard" />
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: "0.75rem"}}>Scheduled Date:</FormLabel>
                    <Input id="householdScheduledDate" type="date" inputRef={scheduledDateRef} />
                </FormControl>
                <Button type="submit" variant="contained" onClick={onSubmitHandler} onSubmit={onSubmitHandler}>{ isLoading ? "Creating..." : "Create Household"}</Button>
            </FormControl>
        </form>
    );

};

export default NewHousehold;