import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import useSWR from "swr";
import Loading from '../../components/Loading';

import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { useState } from 'react';

import AccountActions from '../../components/AccountActions';
import { constants } from '../../utils/constants';

import Error from '../Error';
import AccountProfile from '../../components/AccountProfile';

import Paper from "@mui/material/Paper";
import HouseholdRandomization from '../../components/HouseholdRandomization';

function Household({ link }) {

    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false);};

    const [ sendNotification ] = useOutletContext();

    async function fetcher() {

        const householdURL = `${link}/household/h/${id}`;

        const householdReq = await fetch(householdURL, {
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            method: "GET"
        });

        const household = await householdReq.json();

        if (!household.household._id) {

            throw new Error("Household does not exist.");

        }

        return { household }


    }

    const { data, error, isLoading } = useSWR(`/household/h/${id}`, fetcher, { revalidateIfStale: true, revalidateOnFocus: true, revalidateOnMount: true, revalidateOnReconnect: true });

    if (isLoading) return <Loading />;
    if (error || data.household.household === null) return <Error message={"Failed to fetch household. Either it does not exist or the database is offline."}/>;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
      };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Confirmation of Deletion
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure about deleting {data.household.household.householdName}?
                    </Typography>
                    <ButtonGroup>
                        <Button onClick={async () => {
                                const deletionReq = await fetch(`${constants.BACKEND_ADDRESS}/household/h/${data.household.household._id}`, {
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    method: "DELETE",
                                    credentials: "include"
                                });

                                const deletion = await deletionReq.json();

                                if (deletion.notEnoughPermissionsErr) {

                                    return navigate("/dashboard/error", { state: { navMessage: "Action failed. You do not have enough permissions to perform this action." }});

                                }

                                sendNotification("Successfully deleted household.");

                                return navigate("/dashboard/households")}}
                                >Yes</Button>
                        <Button onClick={handleClose}>No</Button>
                    </ButtonGroup>
                </Grid>
            </Modal>
            <Grid container direction="column" rowSpacing={2}>
                <Grid>
                    <Typography variant="h3" component="h1" sx={{ fontWeight: "bolder" }}>
                        {data.household.household.householdName.toUpperCase()}
                    </Typography>
                    <Typography variant="p" component="h2" sx={{ fontSize: "1rem", opacity: "0.5" }} >
                        HOUSEHOLD
                    </Typography>
                </Grid>
                <Grid>
                    <Paper elevation={2} sx={{paddingTop: "1rem", paddingBottom: "1rem"}}>
                        <AccountProfile id={data.household.household._id}  
                            householdAddress={data.household.household.householdAddress}
                            town={data.household.household.householdTown}
                            scheduledDate={new Date(data.household.household.scheduledDate).toLocaleDateString('en-US', { timeZone: 'Pacific/Port_Moresby' })}
                            adultSurveyee={data.household.household.adultSurveyee || "None."}
                            childSurveyee={data.household.household.childSurveyee || "None."}
                        />
                    </Paper>
                </Grid>
                <Grid>
                    <AccountActions id={id} type="household" 
                    prefilledData={{householdName: data.household.household.householdName,
                                    householdAddress: data.household.household.householdAddress,
                                    householdTown: data.household.household.householdTown,
                                    adultSurveyee: data.household.household.adultSurveyee,
                                    childSurveyee: data.household.household.childSurveyee,
                                    scheduledDate: data.household.household.scheduledDate,
                                    otherHouseholdMembers: data.household.household.otherHouseholdMembers
                                    }}
                    onDelete={handleOpen}/>
                </Grid>
                <Grid>
                    <Paper elevation={2} sx={{paddingTop: "1rem", paddingBottom: "1rem"}}>
                        <Typography variant="h6" component="h3" color="#1976D2" sx={{ fontWeight: "bolder" }}>
                            Randomization
                        </Typography>
                        <HouseholdRandomization link={link} householdId={id} householdMembersLength={data.household.household.otherHouseholdMembers}/>
                    </Paper>
                </Grid>
                <Grid>
                    <Paper elevation={2} sx={{paddingTop: "1rem", paddingBottom: "1rem"}}>
                    <Typography variant="h6" component="h3" color="#1976D2" sx={{ fontWeight: "bolder" }}>
                        Other Household Members
                    </Typography>
                        <Typography variant="p" component="h3" sx={{ fontSize: "1rem", opacity: "0.5" }} >
                            {data.household.household.otherHouseholdMembers.length > 0 ? data.household.household.otherHouseholdMembers.join(", ") : "None."}
                        </Typography>   
                    </Paper>
                </Grid>
            </Grid>
            
        </>
    );

};

export default Household;