import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { constants } from '../../utils/constants';

import { useRef, useEffect, useState } from "react";

import { useOutletContext } from "react-router-dom";

import useSWR from "swr";

function UpdateSurveyee({ id, closer, prefilledData }) {

    const [gender, setGender] = useState('');
    const [ethnicity, setEthnicity] = useState('');
    const [race, setRace] = useState('');

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const ageRef = useRef();
    const commentRef = useRef();

    const [ sendNotification ] = useOutletContext();

    useEffect(() => {

        if (prefilledData) {

            firstNameRef.current.value = prefilledData.firstName || "";
            lastNameRef.current.value = prefilledData.lastName || "";
            ageRef.current.value = prefilledData.age || "";
            commentRef.current.value = prefilledData.comment || "";

            setEthnicity(prefilledData.ethnicity);
            setGender(prefilledData.gender);
            setRace(prefilledData.race);

        }

    }, [prefilledData]);

    const fetcher = async function() {

        const updateSurveyeeReq = await fetch(`${constants.BACKEND_ADDRESS}/patient/update/${id}`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            credentials: "include",
            body: JSON.stringify({
                firstName: firstNameRef.current.value,
                lastName: lastNameRef.current.value,
                age: ageRef.current.value,
                race: race,
                gender: gender,
                ethnicity: ethnicity,
                id: id,
                comment: commentRef.current.value
            })
        });

        const updateSurveyee = await updateSurveyeeReq.json();

        if (updateSurveyee.error) {

            sendNotification("You have an error in your inputs. Please try again.");

        } else {

            sendNotification("Successfully updated surveyee.");

        }

        return { updateSurveyee }
        

    }

    const handleSexChange = (event) => {

        setGender(event.target.value);
  
    };

    const handleEthnicityChange = (event) => {

        setEthnicity(event.target.value);
  
      }
  
      const handleRaceChange = (event) => {
              
          setRace(event.target.value);
  
      }

    const { data, isLoading, error, mutate } = useSWR("/api/surveyee/update", fetcher,
    { keepPreviousData: false, revalidateIfStale: false, 
        revalidateOnReconnect: false, revalidateOnMount: false, revalidateOnFocus: false});



    const onSubmitHandler = (e) => {

        e.preventDefault();
        mutate();
        closer();

    };

    return (<>
        <form>
            <FormControl sx={{display: "flex", justifyContent: "space-around", gap: "20px"}}>
                <TextField inputRef={firstNameRef} label="First Name" variant="standard" />
                <TextField inputRef={lastNameRef} label="Last Name" variant="standard" />
                <TextField inputRef={ageRef} label="Age" variant="standard" />
                <FormControl>
                    <Select 
                    id="race" 
                    value={race} 
                    onChange={handleRaceChange} 
                    label="Race" 
                    displayEmpty
                    >
                        <MenuItem value={""} disabled>Race</MenuItem>
                        <MenuItem value="White">White</MenuItem>
                        <MenuItem value="Black or African American">Black</MenuItem>
                        <MenuItem value="American Indian or Alaskan Native">American Indian or Alaskan Native</MenuItem>
                        <MenuItem value="Pacific Islander">Pacific Islander</MenuItem>
                        <MenuItem value="Asian">Asian</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select 
                    id="ethnicity" 
                    value={ethnicity} 
                    onChange={handleEthnicityChange} 
                    label="Ethnicity" 
                    displayEmpty
                    >
                        <MenuItem value={""} disabled>Ethnicity</MenuItem>
                        <MenuItem value="Carolinian">Carolinian</MenuItem>
                        <MenuItem value="CHamorro">CHamorro</MenuItem>
                        <MenuItem value="Chuukese">Chuukese</MenuItem>
                        <MenuItem value="Guamanian">Guamanian</MenuItem>
                        <MenuItem value="Kosraean">Kosraean</MenuItem>
                        <MenuItem value="Marshallese">Marshallese</MenuItem>
                        <MenuItem value="Native Hawaiian">Native Hawaiian</MenuItem>
                        <MenuItem value="Palauan">Palauan</MenuItem>
                        <MenuItem value="Pohnpeian">Pohnpeian</MenuItem>
                        <MenuItem value="Yapese">Yapese</MenuItem>
                        <MenuItem value="Chinese">Chinese</MenuItem>
                        <MenuItem value="Filipino">Filipino</MenuItem>
                        <MenuItem value="Taiwanese">Taiwanese</MenuItem>
                        <MenuItem value="Korean">Korean</MenuItem>
                        <MenuItem value="Thai">Thai</MenuItem>
                        <MenuItem value="Vietnamese">Vietnamese</MenuItem>
                        <MenuItem value="Japanese">Japanese</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                <Select
                value={gender}
                label="Sex"
                onChange={handleSexChange}
                displayEmpty
                >   
                    <MenuItem value={""} disabled>Sex</MenuItem>
                    <MenuItem value={"M"}>Male</MenuItem>
                    <MenuItem value={"F"}>Female</MenuItem>
                </Select>
                <TextField inputRef={commentRef} label="Comment" variant="standard" />
                <Button type="submit" variant="contained" onClick={onSubmitHandler}>{ isLoading ? "Creating..." : "Create Patient"}</Button>
            </FormControl>
        </form>
    </>);


};

export default UpdateSurveyee;