import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import useSWR from "swr";
import Loading from '../../components/Loading';

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { useParams, useNavigate } from 'react-router-dom';
import AccountActions from '../../components/AccountActions';

import { useState } from "react";

import { constants } from '../../utils/constants';

import Error from '../Error';

import { useOutletContext } from "react-router-dom";
import AccountProfile from '../../components/AccountProfile';

import Paper from '@mui/material/Paper';


function Account({ link }) {

    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false);};

    const [ sendNotification ] = useOutletContext();


    async function fetcher() {

        const accountURL = `${link}/a/${id}`;

        const accountReq = await fetch(accountURL, {
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            method: "GET"
        });

        const account = await accountReq.json();

        if (!account.account._id) {

            throw new Error("Account does not exist.");

        }

        return { account }

    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const { data, error, isLoading } = useSWR(`/a/${id}`, fetcher);


    if (isLoading) return <Loading />;
    if (error) return <Error message={"Failed to fetch account. Either it does not exist or the database is offline."}/>;

    if (data)
    return (<>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Confirmation of Deletion
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure about deleting {data.account.account.username}?
                    </Typography>
                    <ButtonGroup>
                        <Button onClick={async () => {
                                const deletionReq = await fetch(`${constants.BACKEND_ADDRESS}/a/delete/${data.account.account._id}`, {
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    method: "DELETE",
                                    credentials: "include"
                                });

                                const deletion = await deletionReq.json();

                                if (deletion.notEnoughPermissionsErr) {

                                    return navigate("/dashboard/error", { state: { navMessage: "Action failed. You do not have enough permissions to perform this action." }});

                                }

                                sendNotification("Successfully deleted account.");
                                return navigate("/dashboard/accounts")}}
                                >Yes</Button>
                        <Button onClick={handleClose}>No</Button>
                    </ButtonGroup>
                </Grid>
            </Modal>
        <Grid container direction="column" rowSpacing={2}>
            <Grid>

                    <Typography variant="h3" component="h1" sx={{ fontWeight: "bolder" }}>
                        {data.account.account.username.toUpperCase()}
                    </Typography>
                    <Typography variant="p" component="h2" sx={{ fontSize: "1rem", opacity: "0.5" }} >
                        ACCOUNT
                    </Typography>

            </Grid>
            <Grid>
                <Paper elevation={2} sx={{paddingTop: "1rem", paddingBottom: "1rem"}}>
                    <AccountProfile id={data.account.account._id}
                            createdAt={data.account.account.createdAt}
                            updatedAt={data.account.account.updatedAt}
                            username={data.account.account.username}
                            role={data.account.account.role}
                    />
                </Paper>
            </Grid>
            <Grid>
                <AccountActions id={data.account.account._id} type="account" prefilledData={{username: data.account.account.username, email: data.account.account.email, role: data.account.account.role }}
                onDelete={handleOpen}/>
            </Grid>
        </Grid>
    </>
    );

    return <Error message={"Failed to fetch account. Either it does not exist or the database is offline."} />;

};

export default Account;