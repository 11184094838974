import DashboardCard from "../components/DashboardCard";
import Grid from '@mui/material/Unstable_Grid2';

import useSWR from "swr";
import CustomListItem from "../components/CustomListItem";

function Main({ link }) {

    async function fetcher() {

        const scheduledTodayReq = await fetch(`${link}/household/scheduled/today`, { credentials: "include" });

        const scheduledToday = await scheduledTodayReq.json();

        return { scheduledToday }

    }

    const { data, isLoading, error } = useSWR("/api/scheduled", fetcher, {
        revalidateIfStale: false,
        revalidateOnMount: true,
        revalidateOnReconnect: true,
        revalidateOnFocus: false
    });

    console.log(data);

    return (<>
        <Grid container direction="column">
            <Grid xs={12} md={6} lg={6}>
                <h2>Overview</h2>
            </Grid>
        </Grid>
        <Grid container justifyContent="space-around" direction={"row"} marginTop="3vh">
            <Grid xs={12} md={4} lg={2}>
                <DashboardCard link={`${link}/households?count=true`} countKey="count" label="Households" color="#f3e5f5" textColor="#ab47bc" iconType="household" />
            </Grid>
            <Grid xs={12} md={4} lg={2}>
                <DashboardCard link={`${link}/patients?count=true`} countKey="count" label="Surveyees" color="#f3e5f5" textColor="#ab47bc" iconType="surveyee" />
            </Grid>
            <Grid xs={12} md={4} lg={2}>
                <DashboardCard link={`${link}/accounts?count=true`} countKey="count" label="Accounts" color="#f3e5f5" textColor="#ab47bc" iconType="account" />
            </Grid>
        </Grid>
        <Grid container direction={"row"} marginTop="3vh">
            <Grid xs={12}>
                <h2>Today's Scheduled Households ({new Date(Date.now()).toLocaleDateString("en-US", { timeZone: "Pacific/Port_Moresby"})})</h2>
            </Grid>
            <Grid xs={12} overflow="auto" minHeight="10vh" maxHeight="40vh" marginTop="3vh">
                { isLoading ? "Loading..." : null}
                { error ? "An error ocurred" : null}
                { !isLoading && data ? data?.scheduledToday?.scheduledHouseholds?.map((i) => <CustomListItem key={i._id} title={i.householdName} text={i._id} link={`/dashboard/household/${i._id}`}/>): null}
                { !isLoading && data?.scheduledToday?.scheduledHouseholds?.length <= 0 ? "No household scheduled for today." : null }
            </Grid>
        </Grid>
    </>);

};

export default Main;