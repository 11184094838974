import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { constants } from '../../utils/constants';

import { useRef, useEffect } from "react";

import { useOutletContext } from "react-router-dom";

import useSWR from "swr";
import Loading from '../Loading';

function UpdateAccount({ id, closer, prefilledData }) {

    const emailRef = useRef();
    const usernameRef = useRef();
    const roleRef = useRef();

    const [ sendNotification ] = useOutletContext();

    useEffect(() => {

        if (prefilledData) {

            emailRef.current.value = prefilledData.email;
            usernameRef.current.value = prefilledData.username;
            roleRef.current.value = prefilledData.role;

        }

    }, [prefilledData])

    const fetcher = async function() {

        const updateAccountReq = await fetch(`${constants.BACKEND_ADDRESS}/a/update/${id}`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            credentials: "include",
            body: JSON.stringify({
                email: emailRef.current.value,
                username: usernameRef.current.value,
                role: roleRef.current.value,
                id: id
            })
        });

        const updateAccount = await updateAccountReq.json();

        if (updateAccount.error) {

            sendNotification("You have an error in your inputs. Please try again.");

        } else {

            sendNotification("Account has been updated successfully.");

        }

        return { updateAccount }
        

    }

    const { data, isLoading, error, mutate } = useSWR("/api/account/update", fetcher,
    { keepPreviousData: false, revalidateIfStale: false, revalidateOnReconnect: false, revalidateOnMount: false, revalidateOnFocus: false });

    
    const onSubmitHandler = (e) => {

        e.preventDefault();
        mutate();
        closer();

    };

    return (
        <form>
            <FormControl sx={{display: "flex", justifyContent: "space-around", gap: "20px"}}>
                <TextField id="username" inputRef={usernameRef} variant="standard" type="text" label="Username"></TextField>
                <TextField id="email" inputRef={emailRef} variant="standard" type="email" label="Email"></TextField>
                <TextField id="role" inputRef={roleRef} variant="standard" type="text" label="Role"></TextField>
                <Button type="submit" variant="contained" onClick={onSubmitHandler}>{isLoading ? <Loading /> : "Update Account"}</Button>
            </FormControl>
        </form>
    );


};

export default UpdateAccount;