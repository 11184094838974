import Grid from '@mui/material/Unstable_Grid2';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';

import Loading from "../components/Loading";

import { useEffect, useState } from "react";

import useSWR from "swr";
import Navigation from '../components/Navigation';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ActionButton from '../components/ActionButton';
import Snackbar from '@mui/material/Snackbar';
import Error from './Error';

function Dashboard({ link }) {

    const [ isOpen, setIsOpen ] = useState(false);
    const [ snackBarOpen, setSnackBarOpen ] = useState(false);
    const [ snackBarMessage, setSnackBarMessage ] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    const { data, error, isLoading } = useSWR("/verifySession", async function() {

        const verifySessionReq = await fetch(`${link}/verifySession`, { method: "GET", credentials: "include" });

        const verifySession = await verifySessionReq.json();

        return { verifySession }

    });

    useEffect(() => {

        setIsOpen(false);

    }, [location])

    function toggleDrawer() {

        setIsOpen((prev) => !prev);

    };

    function sendNotification(message) {

        setSnackBarOpen(true);
        setSnackBarMessage(message);

    }

    if (error) return <Error message={"An unknown error occurred while loading the application."}/>;
    if (isLoading) return <Loading wholeScreen={true} />;

    if (data.verifySession.authenticated === true)
    return (
        <>
            <Grid container direction="column" rowSpacing={5}>
                <Grid>
                    <Navigation open={isOpen} toggleDrawer={toggleDrawer} anchor="left" username={data.verifySession.username || "Your Account"}/>
                </Grid>
                <Grid justifyContent="center" alignItems="center">
                    <AppBar>
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={toggleDrawer}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Guma Tinemtom
                            </Typography>
                            <Button onClick={async () => {
                                const logoutAction = await fetch(`${link}/logout`, { credentials: "include" });
                                const logout = await logoutAction.json();
                                if (logout.message === "Logged out successfully.") {

                                    return navigate("/", { replace: true, state: { isLoggingOff: true }} );

                                }
                            }} color="inherit">Logout</Button>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid justifyContent="center" alignItems="center">
                    <Outlet context={[sendNotification]}/>
                </Grid>
            </Grid>
            <ActionButton notifier={sendNotification} />
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBarOpen(false)}
                message={snackBarMessage}
            />
        </>
    );

    return <Error message={"You are not logged in."}/>;

};

export default Dashboard;