import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'survey-core/defaultV2.css';

import Home from "./pages/Home";
import Dashboard from './pages/Dashboard';
import Main from './pages/Main';
import Households from './pages/Dashboards/Households';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Surveyees from './pages/Dashboards/Surveyees';
import Household from './pages/Dashboards/Household';
import Account from './pages/Dashboards/Account';
import Accounts from './pages/Dashboards/Accounts';
import Surveyee from './pages/Dashboards/Surveyee';
import AccountSurvey from './pages/Dashboards/AccountSurvey';
import Data from './pages/Dashboards/Data';
import Me from './pages/Dashboards/Me';

import { constants } from './utils/constants';
import Error from './pages/Error';
import DashboardError from './pages/Dashboards/DashboardError';
import Logs from './pages/Dashboards/Logs';

const router = createBrowserRouter([
  {errorElement: <Error />, children: [
    {path: "/", index: true, element: <Home link={constants.BACKEND_ADDRESS} />},
    {path: "/dashboard", element: <Dashboard link={constants.BACKEND_ADDRESS} />, children: [
      {path: "/dashboard/households", element: <Households link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/surveyees", element: <Surveyees link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/accounts", element: <Accounts link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/account/:id", element: <Account link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/household/:id", element: <Household link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/surveyee/:id", element: <Surveyee link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/surveyee/:id/survey/:surveyModule", element: <AccountSurvey link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/data", element: <Data link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/logs", element: <Logs link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/me", element: <Me link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard", element: <Main link={constants.BACKEND_ADDRESS} />},
      {path: "/dashboard/error", element: <DashboardError message={"User triggered error."}/>}
    ]}
  ]}
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  
);

reportWebVitals();
