import Grid from '@mui/material/Unstable_Grid2';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import useSWR from 'swr';

import { useState, useEffect } from "react";

import { useOutletContext } from "react-router-dom";

import Loading from '../../components/Loading';
import Error from '../Error';

function Me({ link }) {

    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ username, setUsername ] = useState("");
    const [ enableTwoFactorAuthentication, setEnableTwoFactorAuthentication ] = useState(false);    

    const [ sendNotification ] = useOutletContext();

    useEffect(() => {

        async function fetchMe() {

            const meReq = await fetch(`${link}/me`, {
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                method: "GET"
            });
    
            const me = await meReq.json();
    
            setEmail(me.account.email);
            setUsername(me.account.username);
            setEnableTwoFactorAuthentication(me.account.twoFactorAuthentication);

        };
        
        fetchMe();

    }, [])

    async function fetcher() {

        console.log(enableTwoFactorAuthentication);

        const meReq = await fetch(`${link}/me`, {
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            method: "POST",
            body: password === confirmPassword && password ? JSON.stringify({
                username,
                email,
                twoFactorAuthentication: enableTwoFactorAuthentication,
                password
            }) : JSON.stringify({
                username,
                twoFactorAuthentication: enableTwoFactorAuthentication,
                email
            })
        });

        const me = await meReq.json();

        if (me) {

            sendNotification("Your changes have been submitted.");

        }

    }

    const { data, isLoading, error, mutate } = useSWR(`/api/me`, fetcher, {
        revalidateIfStale: false, revalidateOnFocus: false, 
        revalidateOnReconnect: false, revalidateOnMount: false, 
        keepPreviousData: false
    });

    if (isLoading) return <Loading />;
    if (error) return <Error />;

    return (
        <Grid container direction="column" rowSpacing={2}>
            <Grid>
                <h2>Me</h2>
            </Grid>
            <Grid>
                <FormControl>
                    <Grid>
                        <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="email" label="Email" type="email" variant="outlined" />
                    </Grid>
                    <Grid>
                        <TextField value={username} onChange={(e) => setUsername(e.target.value)} id="username" label="Username" type="text" variant="outlined" />
                    </Grid>
                    <Grid>
                        <TextField value={password} onChange={(e) => setPassword(e.target.value)} id="password" label="Password" type="password" variant="outlined" />
                    </Grid>
                    <Grid>
                        <TextField value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} id="confirmPassword" label="Confirm Password" type="password" variant="outlined" />
                    </Grid>
                    <Grid>
                        <p>Enable Two Factor Authentication?</p>
                        <p>By enabling two factor authentication, a 6-digit code will be sent to your email every time you attempt to login.</p>
                        <p>It is highly recommended for you to turn this on.</p>
                        <Switch checked={enableTwoFactorAuthentication} onChange={(e) => setEnableTwoFactorAuthentication(e.target.checked)} label="Two Factor Authentication" />
                    </Grid>
                    <Grid>
                        <Button variant="outlined" type="submit" onClick={(e) => {
                            e.preventDefault();
                            mutate();
                        }} disabled={!((email && username && !password && !confirmPassword) || (email && username && (password === confirmPassword) && (password.length > 7)))}>Submit</Button>
                    </Grid>
                </FormControl>
            </Grid>
        </Grid>
    );

};

export default Me;