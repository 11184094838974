import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import { useState, useRef } from "react";

import { constants } from '../../utils/constants';

import useSWR from "swr";

function NewSurveyee({ closer, notifier }) {

    const [gender, setGender] = useState('');
    const [ethnicity, setEthnicity] = useState('');
    const [race, setRace] = useState('');

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const ageRef = useRef();
    const raceRef = useRef();

    const fetcher = async function() {

        const body = {
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            gender: gender,
            age: ageRef.current.value,
            race: race,
            ethnicity: ethnicity
        }

        const createPatientReq = await fetch(`${constants.BACKEND_ADDRESS}/patient/create`, {
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify(body)
        });

        const createPatient = await createPatientReq.json();

        if (!createPatient.notEnoughPermissionsErr) {
            notifier("Created Surveyee with ID: " + createPatient?.account?._id);
        }
        else if (createPatient.notEnoughPermissionsErr) {
            notifier("You do not have enough permissions to create a new surveyee.");
        } else {
            notifier("An unknown error occurred while creating a surveyee.");
        }

        
        return { createPatient };

    }

    const { data, error, isLoading, mutate } = useSWR("/api/surveyee/new", fetcher, 
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false, keepPreviousData: false, revalidateOnMount: false });

    const handleSexChange = (event) => {

      setGender(event.target.value);

    };

    const handleEthnicityChange = (event) => {

      setEthnicity(event.target.value);

    }

    const handleRaceChange = (event) => {
            
        setRace(event.target.value);

    }

    const onSubmitHandler = (e) => {

        e.preventDefault();
        mutate();
        closer();

    };

    return (
        <form>
            <FormControl sx={{display: "flex", justifyContent: "space-around", gap: "20px"}}>
                <TextField id="firstName" inputRef={firstNameRef} label="First Name" variant="standard" />
                <TextField id="lastName" inputRef={lastNameRef} label="Last Name" variant="standard" />
                <TextField id="age" inputRef={ageRef} label="Age" variant="standard" />
                {/* <TextField id="race" inputRef={raceRef} label="Race" variant="standard" /> */}
                <FormControl>
                    <Select 
                    id="race" 
                    value={race} 
                    onChange={handleRaceChange} 
                    label="Race" 
                    displayEmpty
                    >
                        <MenuItem value={""} disabled>Race</MenuItem>
                        <MenuItem value="White">White</MenuItem>
                        <MenuItem value="Black or African American">Black</MenuItem>
                        <MenuItem value="American Indian or Alaskan Native">American Indian or Alaskan Native</MenuItem>
                        <MenuItem value="Pacific Islander">Pacific Islander</MenuItem>
                        <MenuItem value="Asian">Asian</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select 
                    id="ethnicity" 
                    value={ethnicity} 
                    onChange={handleEthnicityChange} 
                    label="Ethnicity" 
                    displayEmpty
                    >
                        <MenuItem value={""} disabled>Ethnicity</MenuItem>
                        <MenuItem value="Carolinian">Carolinian</MenuItem>
                        <MenuItem value="CHamorro">CHamorro</MenuItem>
                        <MenuItem value="Chuukese">Chuukese</MenuItem>
                        <MenuItem value="Guamanian">Guamanian</MenuItem>
                        <MenuItem value="Kosraean">Kosraean</MenuItem>
                        <MenuItem value="Marshallese">Marshallese</MenuItem>
                        <MenuItem value="Native Hawaiian">Native Hawaiian</MenuItem>
                        <MenuItem value="Palauan">Palauan</MenuItem>
                        <MenuItem value="Pohnpeian">Pohnpeian</MenuItem>
                        <MenuItem value="Yapese">Yapese</MenuItem>
                        <MenuItem value="Chinese">Chinese</MenuItem>
                        <MenuItem value="Filipino">Filipino</MenuItem>
                        <MenuItem value="Taiwanese">Taiwanese</MenuItem>
                        <MenuItem value="Korean">Korean</MenuItem>
                        <MenuItem value="Thai">Thai</MenuItem>
                        <MenuItem value="Vietnamese">Vietnamese</MenuItem>
                        <MenuItem value="Japanese">Japanese</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                    value={gender}
                    label="Sex"
                    onChange={handleSexChange}
                    id="sex"
                    displayEmpty
                    >   
                        <MenuItem value={""} disabled>Sex</MenuItem>
                        <MenuItem value={"M"}>Male</MenuItem>
                        <MenuItem value={"F"}>Female</MenuItem>
                    </Select>
                </FormControl>
                <Button type="submit" variant="contained" onClick={onSubmitHandler}>{ isLoading ? "Creating..." : "Create Patient"}</Button>
            </FormControl>
        </form>
    );


};

export default NewSurveyee;