export const phy = [
    {
        type: "text",
        inputType: "number",
        name: "MODN_A",
        title: "How often do you do MODERATE-INTENSITY LEISURE-TIME physical activities?",
        description: "Moderate-intensity activities cause moderate increases in breathing or heart rate.",
        isRequired: false,
        placeHolder: "Enter the frequency (000-995)",
        validators: [
            {
                type: "numeric",
                minValue: 0,
                maxValue: 995,
                text: "Please enter a number between 000 and 995."
            }
        ],
        requiredErrorText: "Please enter the frequency of moderate-intensity leisure-time physical activities.",
        visibleIf: true // Visible for Sample Adults 18+
        // Universe logic for NHIS 'Sample Adults 18+' is set to true to make the question visible for all in this demographic.
        // You might need to add skip logic or adjust the visibility conditions based on your specific survey criteria.
    },
    {
        type: "radiogroup",
        name: "MODTP_A",
        title: "Enter time period for moderate leisure-time physical activities.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Per day"
            },
            {
                value: 2,
                text: "Per week"
            },
            {
                value: 3,
                text: "Per month"
            },
            {
                value: 4,
                text: "Per year"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select a time period for moderate leisure-time physical activities.",
        visibleIf: "{MODN_A} > 0" // Assuming 'MODN_A' represents the frequency of moderate-intensity leisure-time physical activities
        // Universe logic for NHIS 'Sample Adults 18+ who do moderate physical activities' is applied through the 'visibleIf' condition.
        // Skip logic can be added if needed.
    },
    {
        type: "radiogroup",
        name: "MODTP_A",
        title: "Enter time period for moderate leisure-time physical activities.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Per day"
            },
            {
                value: 2,
                text: "Per week"
            },
            {
                value: 3,
                text: "Per month"
            },
            {
                value: 4,
                text: "Per year"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select a time period for moderate leisure-time physical activities.",
        visibleIf: "{MODN_A} > 0" // Assuming 'MODN_A' represents the frequency of moderate-intensity leisure-time physical activities
        // Universe logic for NHIS 'Sample Adults 18+ who do moderate physical activities' is applied through the 'visibleIf' condition.
        // Skip logic can be added if needed.
    },
    {
        type: "text",
        inputType: "number",
        name: "MODLN_A",
        title: "About how long do you do these moderate leisure-time physical activities each time?",
        description: "Moderate-intensity activities cause moderate increases in breathing or heart rate.",
        isRequired: false,
        placeHolder: "Enter the duration (001-995)",
        validators: [
            {
                type: "numeric",
                minValue: 1,
                maxValue: 995,
                text: "Please enter a number between 001 and 995."
            }
        ],
        requiredErrorText: "Please enter the duration of moderate leisure-time physical activities.",
        visibleIf: "{MODN_A} > 0" // Assuming 'MODN_A' represents the frequency of moderate-intensity leisure-time physical activities
        // Universe logic for NHIS 'Sample Adults 18+ who do moderate physical activities' is applied through the 'visibleIf' condition.
        // Skip logic can be added if needed.
    },
    {
        type: "radiogroup",
        name: "MODLNTP_A",
        title: "Enter time period for moderate leisure-time physical activities.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Per day"
            },
            {
                value: 2,
                text: "Per week"
            },
            {
                value: 3,
                text: "Per month"
            },
            {
                value: 4,
                text: "Per year"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select a time period for moderate leisure-time physical activities.",
        visibleIf: "{MODLN_A} > 0" // Assuming 'MODN_A' represents the frequency of moderate-intensity leisure-time physical activities
        // Universe logic for NHIS 'Sample Adults 18+ who do moderate physical activities' is applied through the 'visibleIf' condition.
        // Skip logic can be added if needed.
    },
    {
        type: "text",
        inputType: "number",
        name: "VIGN_A",
        title: "How often do you do VIGOROUS-INTENSITY LEISURE-TIME physical activities?",
        description: "Vigorous-intensity activities cause large increases in breathing or heart rate.",
        isRequired: false,
        placeHolder: "Enter the frequency (000-995)",
        validators: [
            {
                type: "numeric",
                minValue: 0,
                maxValue: 995,
                text: "Please enter a number between 000 and 995."
            }
        ],
        requiredErrorText: "Please enter the frequency of vigorous-intensity leisure-time physical activities.",
        visibleIf: true // Assuming this question is relevant for all Sample Adults 18+
        // Universe logic for NHIS 'Sample Adults 18+' is set to true to make the question visible for all in this demographic.
        // You might need to add skip logic or adjust the visibility conditions based on your specific survey criteria.
    },
    {
        type: "radiogroup",
        name: "VIGTP_A",
        title: "Enter time period for vigorous leisure-time physical activities.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Per day"
            },
            {
                value: 2,
                text: "Per week"
            },
            {
                value: 3,
                text: "Per month"
            },
            {
                value: 4,
                text: "Per year"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select a time period for vigorous leisure-time physical activities.",
        visibleIf: "{VIGN_A} > 0" // Assuming 'VIGN_A' represents the frequency of vigorous-intensity leisure-time physical activities
        // Universe logic for NHIS 'Sample Adults 18+ who do vigorous physical activities' is applied through the 'visibleIf' condition.
        // Skip logic can be added if needed.
    },
    {
        type: "text",
        inputType: "number",
        name: "VIGLN_A",
        title: "About how long do you do these vigorous leisure-time physical activities each time?",
        description: "Vigorous-intensity activities cause large increases in breathing or heart rate.",
        isRequired: false,
        placeHolder: "Enter the duration (001-995)",
        validators: [
            {
                type: "numeric",
                minValue: 1,
                maxValue: 995,
                text: "Please enter a number between 001 and 995."
            }
        ],
        requiredErrorText: "Please enter the duration of vigorous leisure-time physical activities.",
        visibleIf: "{VIGN_A} > 0" // Assuming 'VIGN_A' represents the frequency of vigorous-intensity leisure-time physical activities
        // Universe logic for NHIS 'Sample Adults 18+ who do vigorous physical activities' is applied through the 'visibleIf' condition.
        // Skip logic can be added if needed.
    },
    {
        type: "radiogroup",
        name: "VIGLTP_A",
        title: "Enter time period for length of vigorous leisure-time physical activities.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Minutes"
            },
            {
                value: 2,
                text: "Hours"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select a time period for length of vigorous leisure-time physical activities.",
        visibleIf: "{VIGN_A} > 0" // Assuming 'VIGN_A' represents the frequency of vigorous-intensity leisure-time physical activities
        // Universe logic for NHIS 'Sample Adults 18+ who do vigorous physical activities' is applied through the 'visibleIf' condition.
        // Skip logic can be added if needed.
    },
    {
        type: "text",
        inputType: "number",
        name: "STRN_A",
        title: "How often do you do LEISURE-TIME physical activities specifically designed to STRENGTHEN your muscles?",
        description: "Including activities like sit-ups, push-ups, or lifting weights.",
        isRequired: false,
        placeHolder: "Enter the frequency (000-995)",
        validators: [
            {
                type: "numeric",
                minValue: 0,
                maxValue: 995,
                text: "Please enter a number between 000 and 995."
            }
        ],
        requiredErrorText: "Please enter the frequency of strengthening activities.",
        visibleIf: true // Assuming this question is relevant for all Sample Adults 18+
        // Universe logic for NHIS 'Sample Adults 18+' is set to true to make the question visible for all in this demographic.
        // You might need to add skip logic or adjust the visibility conditions based on your specific survey criteria.
    },
    {
        type: "radiogroup",
        name: "STRTP_A",
        title: "Enter time period for strengthening activities.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Per day"
            },
            {
                value: 2,
                text: "Per week"
            },
            {
                value: 3,
                text: "Per month"
            },
            {
                value: 4,
                text: "Per year"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select a time period for strengthening activities.",
        visibleIf: "{STRN_A} > 0" // Assuming 'STRN_A' represents the frequency of activities specifically designed to strengthen muscles
        // Universe logic for NHIS 'Sample Adults 18+ who do strengthening activities' is applied through the 'visibleIf' condition.
        // Skip logic can be added if needed.
    },
    
];


export const phy_c = [

    {
        type: "radiogroup",
        name: "SPORT_C",
        title: "In the past 12 months, did your child play or participate on a sports team or club or take sports lessons either at school or in the community?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'AGE_C' represents age of the child
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PEGYM_C",
        title: "In the past 12 months, did your child take a Physical Education, PE, or gym class?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'AGE_C' represents age of the child
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "PADAYS_C",
        title: "In a typical week during the school year, how often do you exercise, play a sport, or participate in physical activity for at least 60 minutes a day? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'AGE_C' represents age of the child
        // Skip logic can be implemented here if needed.
        // Skip instructions provided in comments for reference
        // Skip Instructions: <1-4,RF,DK> [goto STRENGTH_C]
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    },
    {
        type: "radiogroup",
        name: "STRENGTH_C",
        title: "In a typical week during the school year, how often do you do exercises to strengthen or tone your muscles, such as sit-ups, push-ups, or weight lifting? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'AGE_C' represents age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto WALK_C]
        // Sample child's name Fill ALIAS of HHSTAT_C=1 your/their
        // if GEN.SEX_FINAL[PX_C]=1 fill "his"; else if GEN.SEX_FINAL[PX_C]=2 fill "her"; else if GEN.SEX_FINAL[PX_C] in (blank,DK,RF): fill "their"
    },
    {
        type: "radiogroup",
        name: "WALK_C",
        title: "In a typical week during the school year, how often do you walk for at least 10 minutes at a time? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'AGE_C' represents age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto BIKE_C]
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    },
    {
        type: "radiogroup",
        name: "BIKE_C",
        title: "In a typical week during the school year, how often do you ride a bike for at least 10 minutes at a time? Would you say never, some days, most days, or every day?",
        isRequired: true,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Never"
            },
            {
                value: 2,
                text: "Some days"
            },
            {
                value: 3,
                text: "Most days"
            },
            {
                value: 4,
                text: "Every day"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true", // Assuming 'AGE_C' represents age of the child
        // Skip logic can be implemented here if needed.
        // Skip Instructions: <1-4,RF,DK> [goto Next Section]
        // Sample child's name Fill ALIAS of HHSTAT_C=1 Never Some days Most days Every day Refused Don't Know
    }
];