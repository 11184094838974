import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import useSWR from "swr";
import Loading from './Loading';

import PersonIcon from '@mui/icons-material/Person';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

function DashboardCard({ link, label, countKey, color, textColor, iconType }) {

    async function fetcher() {

        const statisticReq = await fetch(link, { credentials: "include" });

        const statistic = await statisticReq.json();

        return { statistic }

    }

    const { data, isLoading, error } = useSWR(`/api/statistics/${label}`, fetcher);

    if (isLoading) return <Loading />;
    if (error) console.log(error);

    return <>
        <Card variant="outlined" sx={{ backgroundColor: color }}>
            <CardContent sx={{textAlign: "center"}}>
                {
                    iconType === "account" ? <PersonIcon sx={{color: textColor, height: "3rem", width: "3rem"}}/>
                    : iconType === "surveyee" ? <RecentActorsIcon sx={{color: textColor, height: "3rem", width: "3rem"}}/>
                    : iconType === "household" ? <FamilyRestroomIcon sx={{color: textColor, height: "3rem", width: "3rem"}}/>
                    : null
                }
                <Typography variant="h6" component="h2" sx={{color: textColor, fontWeight: "800"}}>
                    { data["statistic"][countKey] }
                </Typography>
                <p style={{fontWeight: "400", color: textColor}}>
                    { label.toUpperCase() }
                </p>
            </CardContent>
        </Card>
    </>

};

export default DashboardCard;