export const dld = [

    {
        type: "radiogroup",
        name: "ADHDEV_C",
        title: "Has a doctor or other health professional ever told you that your child had Attention-Deficit/Hyperactivity Disorder (ADHD) or Attention-Deficit Disorder (ADD)?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option."
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "ADHDNW_C",
        title: "Do you currently have Attention-Deficit/Hyperactivity Disorder (ADHD) or Attention-Deficit Disorder (ADD)?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{ADHDEV_C} == 1" // Assuming ADHDEV_C represents the previous ADHD diagnosis
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "IDEV1_C",
        title: "Has a doctor or other health professional ever told you that your child had an intellectual disability?",
        description: "Intellectual disability is a term used when there are limits to a person's ability to learn at an expected level and function in their daily life. Some common causes of intellectual disability include: Down syndrome, fetal alcohol syndrome, birth defects, and genetic conditions.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option."
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "IDNW1_C",
        title: "Do you currently have an intellectual disability?",
        description: "Intellectual disability is a term used when there are limits to a person’s ability to learn at an expected level and function in their daily life. Some common causes of intellectual disability include: Down syndrome, fetal alcohol syndrome, birth defects, and genetic conditions.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{IDEV1_C} == 1" // Assuming IDEV1_C represents the previous intellectual disability diagnosis
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "ASDEV_C",
        title: "Has a doctor or other health professional ever told you that your child had Autism, Asperger's disorder, pervasive developmental disorder, or autism spectrum disorder?",
        description: "Developmental delays are significant delays in a child's development. Examples include: cognitive, motor, speech, social, emotional and behavioral delays. ",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option."
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "ASDNW_C",
        title: "Do you currently have Autism, Asperger's disorder, pervasive developmental disorder, or autism spectrum disorder?",
        description: "Developmental delays are significant delays in a child’s development. Examples include: cognitive, motor, speech, social, emotional and behavioral delays. ",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{ASDEV_C} == 1" // Assuming ASDEV_C represents the previous autism spectrum disorder diagnosis
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "DDEV_C",
        title: "Has a doctor or other health professional ever told you that your child had any other developmental delay?",
        description: "Developmental delays are significant delays in a child’s development. Examples include: cognitive, motor, speech, social, emotional and behavioral delays. ",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option."
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "DDNW_C",
        title: "Do you still have this other developmental delay?",
        description: "Developmental delays are significant delays in a child’s development. Examples include: cognitive, motor, speech, social, emotional and behavioral delays. ",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{DDEV_C} == 1" // Assuming DDEV_C represents the previous developmental delay diagnosis
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "LDEV_C",
        title: "Has a representative from a school or a health professional ever told you that your child had a learning disability?",
        description: "A learning disability is difficulty learning in a specific area. Examples include: dyslexia, dyscalculia.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option."
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "LDNW_C",
        title: "Do you currently have a learning disability?",
        description: "A learning disability is difficulty learning in a specific area. Examples include: dyslexia, dyscalculia.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Yes"
            },
            {
                value: 2,
                text: "No"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{DDEV_C} == 1 || {DDNW_C} == 1 || {LDEV_C} == 1" // Assuming DDEV_C, DDNW_C, LDEV_C represent previous diagnoses
        // Skip logic can be implemented here if needed.
    }

];