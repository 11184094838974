import Grid from '@mui/material/Unstable_Grid2';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import { useNavigate } from "react-router-dom";

function Profile({ name }) {

    const navigate = useNavigate();

    return (

        <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">

            <Grid>

                <Avatar>A</Avatar>

            </Grid>

            <Grid>

                <p>{name}</p>

            </Grid>

            <Grid>

                <Button variant="contained" onClick={() => navigate("/dashboard/me")}>User Settings</Button>

            </Grid>


        </Grid>

    );


};

export default Profile;