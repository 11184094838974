export const anx = [
    {
        type: "radiogroup",
        name: "ANXFREQ_A",
        title: "How often do you feel worried, nervous, or anxious?",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Daily"
            },
            {
                value: 2,
                text: "Weekly"
            },
            {
                value: 3,
                text: "Monthly"
            },
            {
                value: 4,
                text: "A few times a year"
            },
            {
                value: 5,
                text: "Never"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "ANXMED_A",
        title: "Do you take any medication when you feel worried, nervous, or anxious? (Prescription, traditional, or both)",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Prescription Medication"
            },
            {
                value: 2,
                text: "Traditional Medication"
            },
            {
                value: 3,
                text: "Both"
            },
            {
                value: 4,
                text: "None"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "ANXLEVEL_A",
        title: "Thinking about the last time you felt worried, nervous, or anxious, which of the following would you say is true:",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "A little"
            },
            {
                value: 2,
                text: "A lot"
            },
            {
                value: 3,
                text: "Somewhere in between a little and a lot"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "DEPFREQ_A",
        title: "How often do you feel depressed?",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Daily"
            },
            {
                value: 2,
                text: "Weekly"
            },
            {
                value: 3,
                text: "Monthly"
            },
            {
                value: 4,
                text: "A few times a year"
            },
            {
                value: 5,
                text: "Never"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "DEPMED_A",
        title: "Do you take any medication for depression? (Prescription, traditional, or both)",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Prescription Medication"
            },
            {
                value: 2,
                text: "Traditional Medication"
            },
            {
                value: 3,
                text: "Both"
            },
            {
                value: 4,
                text: "None"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "DEPLEVEL_A",
        title: "Thinking about the last time you felt depressed, how depressed did you feel?",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "A little"
            },
            {
                value: 2,
                text: "A lot"
            },
            {
                value: 3,
                text: "Somewhere in between a little and a lot"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "{DEPFREQ_A} == '1' || {DEPFREQ_A} == '2' || {DEPFREQ_A} == '3' || {DEPFREQ_A} == '4' || {DEPFREQ_A} == '9' || {DEPMED_A} == '1' || {DEPMED_A} == '9'" // Adjust the conditions based on your skip logic and survey structure
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "ANXWINDDOWN_A",
        title: "I found it hard to wind down",
        isRequired: true,
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup",
        name: "ANXDRYMOUTH_A",
        title: "I was aware of dryness of my mouth",
        isRequired: true,
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup",
        name: "ANXNOPOSITIVEF_A",
        title: "I couldn’t seem to experience any positive feeling at all",
        isRequired: true,
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup",
        name: "ANXDIFFICULTBREATHING_A",
        title: "I experienced breathing difficulty (e.g. excessively rapid breathing, breathlessness in the absence of physical exertion)",
        isRequired: true,
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup",
        name: "ANXLACKINITIATIVE_A",
        title: "I found it difficult to work up the initiative to do things",
        isRequired: true,
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup",
        name: "overreacting",
        title: "I tended to over-react to situations",
        isRequired: true,
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ] 
      },
      {
        type: "radiogroup",
        name: "trembling",
        title: "I experienced trembling (e.g. in the hands)",
        isRequired: true, 
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" }, 
          { value: 1, text: "Applied to me to some degree, or some of the time" }, 
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" }, 
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      { 
        type: "radiogroup", 
        name: "nervous_energy",
        title: "I felt that I was using a lot of nervous energy", 
        isRequired: true, 
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" }, 
          { value: 1, text: "Applied to me to some degree, or some of the time" }, 
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" }, 
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      }, 
      {
        type: "radiogroup", 
        name: "worry_panic",
        title: "I was worried about situations in which I might panic and make a fool of myself", 
        isRequired: true,
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" }, 
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup",
        name: "nothing_to_look_forward",
        title: "I felt that I had nothing to look forward to",
        isRequired: true,
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" }, 
          { value: 3, text: "Applied to me very much or most of the time" } 
        ] 
      }, 
      {
        type: "radiogroup",
        name: "agitated",
        title: "I found myself getting agitated",
        isRequired: true,
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" }, 
          { value: 3, text: "Applied to me very much or most of the time" } 
        ]
      },
      {
        type: "radiogroup",
        name: "difficult_to_relax",
        title: "I found it difficult to relax",
        isRequired: true, 
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" }, 
          { value: 1, text: "Applied to me to some degree, or some of the time" }, 
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" }, 
          { value: 3, text: "Applied to me very much or most of the time" }
        ] 
      }, 
      {
        type: "radiogroup", 
        name: "downhearted_blue", 
        title: "I felt down-hearted and blue", 
        isRequired: true,
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      }, 
      {
        type: "radiogroup",
        name: "intolerant",
        title: "I was intolerant of anything that kept me from getting on with what I was doing",
        isRequired: true,
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup", 
        name: "close_to_panic",
        title: "I felt I was close to panic", 
        isRequired: true, 
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" }, 
          { value: 1, text: "Applied to me to some degree, or some of the time" }, 
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup",
        name: "unable_to_be_enthusiastic",
        title: "I was unable to become enthusiastic about anything",
        isRequired: true,
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" } 
        ]
      },
      {
        type: "radiogroup",
        name: "not_worth_much",
        title: "I felt I wasn’t worth much as a person",
        isRequired: true,
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" } 
        ]
      },
      {
        type: "radiogroup",
        name: "touchy",
        title: "I felt that I was rather touchy",
        isRequired: true, 
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" },
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      },
      {
        type: "radiogroup", 
        name: "heart_action_awareness", 
        title: "I was aware of the action of my heart in the absence of physical exertion (e.g. sense of heart rate increase, heart missing a beat)",
        isRequired: true, 
        colCount: 1,
        choices: [
          { value: 0, text: "Did not apply to me at all" }, 
          { value: 1, text: "Applied to me to some degree, or some of the time" }, 
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" }, 
          { value: 3, text: "Applied to me very much or most of the time" } 
        ] 
      }, 
      {
        type: "radiogroup", 
        name: "scared_without_reason",
        title: "I felt scared without any good reason",
        isRequired: true,
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" }, 
          { value: 1, text: "Applied to me to some degree, or some of the time" }, 
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" }, 
          { value: 3, text: "Applied to me very much or most of the time" } 
        ]
      },
      {
        type: "radiogroup",
        name: "life_meaningless",
        title: "I felt that life was meaningless",
        isRequired: true,
        colCount: 1, 
        choices: [
          { value: 0, text: "Did not apply to me at all" },
          { value: 1, text: "Applied to me to some degree, or some of the time" },
          { value: 2, text: "Applied to me to a considerable degree or a good part of time" }, 
          { value: 3, text: "Applied to me very much or most of the time" }
        ]
      } 
];

export const anx_c = [
    {
        type: "radiogroup",
        name: "ANXFREQ_A",
        title: "How often do you feel worried, nervous, or anxious?",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Daily"
            },
            {
                value: 2,
                text: "Weekly"
            },
            {
                value: 3,
                text: "Monthly"
            },
            {
                value: 4,
                text: "A few times a year"
            },
            {
                value: 5,
                text: "Never"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "ANXMED_A",
        title: "Do you take any medication when you feel worried, nervous, or anxious? (Prescription, traditional, or both)",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "Prescription Medication"
            },
            {
                value: 2,
                text: "Traditional Medication"
            },
            {
                value: 3,
                text: "Both"
            },
            {
                value: 4,
                text: "None"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "ANXLEVEL_A",
        title: "Thinking about the last time you felt worried, nervous, or anxious, would you say you were worried, nervous, or anxious?",
        isRequired: false,
        colCount: 2, // Adjust the colCount as needed for the layout
        choices: [
            {
                value: 1,
                text: "A little"
            },
            {
                value: 2,
                text: "A lot"
            },
            {
                value: 3,
                text: "Somewhere in between a little and a lot"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        visibleIf: "true" // This question does not have specific visibility conditions in the provided NHIS specifications
        // Skip logic can be implemented here if needed.
    }
];