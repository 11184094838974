import Grid from '@mui/material/Unstable_Grid2';
import ErrorIcon from '@mui/icons-material/Error';

function Error({ message }) {

    return (
        <>
            <Grid container direction="column" justifyContent="center" alignItems="center" height="50vh" width="100vw">
                <Grid>
                    <ErrorIcon />
                </Grid>
                <Grid>
                    <p style={{textAlign: "center"}}>{message || "An unknown error occurred."}</p>
                </Grid>
            </Grid>
        </>
    );


};

export default Error;