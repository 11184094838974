import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import FormLabel from '@mui/material/FormLabel';

import { constants } from '../../utils/constants';

import { useRef, useEffect, useState } from "react";

import { useOutletContext } from "react-router-dom";

import useSWR from "swr";

function UpdateHousehold({ id, closer, prefilledData }) {

    const householdNameRef = useRef();
    const householdAddressRef = useRef();
    const householdTownRef = useRef();
    const adultSurveyeeIdRef = useRef();
    const childSurveyeeIdRef = useRef();
    const scheduledDateRef = useRef();

    const [ householdMembers, setHouseholdMembers ] = useState();

    const [ sendNotification ] = useOutletContext();

    useEffect(() => {

        if (prefilledData) {

            householdNameRef.current.value = prefilledData.householdName;
            householdAddressRef.current.value = prefilledData.householdAddress;
            householdTownRef.current.value = prefilledData.householdTown;
            adultSurveyeeIdRef.current.value = prefilledData.adultSurveyee ? prefilledData.adultSurveyee : null;
            childSurveyeeIdRef.current.value = prefilledData.childSurveyee ? prefilledData.childSurveyee : null;
            scheduledDateRef.current.value = (new Date(prefilledData.scheduledDate).toLocaleDateString('en-CA', { timeZone: 'Pacific/Port_Moresby' }));

            setHouseholdMembers(prefilledData.otherHouseholdMembers.join(", "));

        }

    }, [prefilledData]);

    const fetcher = async function() {

        // code below if something like "sodfkskdk, slfkskdfksk, laskdkskdfksd", parse to array.

        const otherHouseholdMembers = householdMembers.split(",").map((member) => member);

        const body = {
            householdName: householdNameRef.current.value,
            householdAddress: householdAddressRef.current.value,
            householdTown: householdTownRef.current.value,
            adultSurveyee: adultSurveyeeIdRef.current.value,
            childSurveyee: childSurveyeeIdRef.current.value,
            scheduledDate: scheduledDateRef.current.value,
            otherHouseholdMembers: otherHouseholdMembers
        }

        const updateHouseholdReq = await fetch(`${constants.BACKEND_ADDRESS}/household/h/${id}`, {
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            method: "PATCH",
            body: JSON.stringify(body)
        });

        const updateHousehold = await updateHouseholdReq.json();

        if (updateHousehold.error) {

            sendNotification("You have an error with your inputs. Please try again.");

        } else {

            sendNotification("Successfully updated household.");

        }

        return { updateHousehold };

    }

    const { data, error, isLoading, mutate } = useSWR("/api/surveyee/new", fetcher, 
    { revalidateIfStale: false, revalidateOnFocus: false, 
        revalidateOnReconnect: false, keepPreviousData: false, revalidateOnMount: false });

    const onSubmitHandler = (e) => {

        e.preventDefault();
        mutate();
        closer();

    };

    return (
        <form>
            <FormControl sx={{display: "flex", justifyContent: "space-around", gap: "20px"}}>
                <TextField inputRef={householdNameRef} label="Household Name" variant="standard" />
                <TextField inputRef={householdAddressRef} label="Household Address" variant="standard" />
                <TextField inputRef={householdTownRef} label="Household Town" variant="standard" />
                <TextField inputRef={adultSurveyeeIdRef} label="Adult Surveyee" variant="standard" />
                <TextField inputRef={childSurveyeeIdRef} label="Child Surveyee" variant="standard" />
                <TextField value={householdMembers} label="Other Household Members" onChange={(e) => setHouseholdMembers(e.target.value)} variant="filled" />
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: "0.75rem"}}>Scheduled Date:</FormLabel>
                    <Input type="date" inputRef={scheduledDateRef} />
                </FormControl>
                <Button type="submit" variant="contained" onClick={onSubmitHandler}>{ isLoading ? "Updating..." : "Update Household"  }</Button>
            </FormControl>
        </form>
    );  

};

export default UpdateHousehold;