export const sdq = [

    {
        type: "radiogroup",
        name: "SDQ1_C",
        title: "I have been considerate of other people's feelings during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true" // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be implemented here if needed.
    },
    {
        type: "radiogroup",
        name: "SDQ2_C",
        title: "I have been restless, overactive, cannot stay still for long during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
    },
    {
        type: "radiogroup",
        name: "SDQ3_C",
        title: "I have been complaining of headaches, stomach-aches, or sickness during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here as required for your survey flow
    },
    {
        type: "radiogroup",
        name: "SDQ4_C",
        title: "I have readily shared toys/games with others during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here as needed for your survey flow
    },
    {
        type: "radiogroup",
        name: "SDQ5_C",
        title: "I have often lost my temper during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ6_C",
        title: "I have preferred to play alone or would rather have been alone than with other youth during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ7_C",
        title: "I have been generally well behaved, usually doing what adults have requested during the past six months",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ8_C",
        title: "I have had many worries or often have seemed worried during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ9_C",
        title: "I have been helpful when someone was hurt, upset, or feeling ill during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ10_C",
        title: "I have been constantly fidgeting or squirming during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ11_C",
        title: "I have had at least one good friend during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ12_C",
        title: "I have often fought with other children or bullied them during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ13_C",
        title: "I have often been unhappy, depressed, or tearful during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ14_C",
        title: "I have generally been liked by other people during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ15_C",
        title: "I have been easily distracted or my concentration wandered during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ16_C",
        title: "I have been nervous in new situations or easily lost confidence during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ17_C",
        title: "I have been kind to younger children during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ18_C",
        title: "I have often lied or cheated during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ19_C",
        title: "I have been picked on or bullied by other children during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ20_C",
        title: "I have often offered to help others, such as parents, teachers, and other children during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ21_C",
        title: "I have thought things out before acting during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ22_C",
        title: "I have stolen from home, school, or elsewhere during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ23_C",
        title: "I have gotten along better with adults rather than with other children.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQ25_C",
        title: "I have had a good attention span, to see work through to the end during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not true"
            },
            {
                value: 2,
                text: "Somewhat true"
            },
            {
                value: 3,
                text: "Certainly true"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQIMP1_C",
        title: "I have had difficulties in one or more of the following areas in the past six months: emotion, concentration, behavior, or being able to get along with other people.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "No - no difficulties"
            },
            {
                value: 2,
                text: "Yes - minor difficulties"
            },
            {
                value: 3,
                text: "Yes - definite difficulties"
            },
            {
                value: 4,
                text: "Yes - severe difficulties"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17
        visibleIf: "true", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQIMP2_C",
        title: "How long have these difficulties been present?",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Less than a month"
            },
            {
                value: 2,
                text: "1-5 months"
            },
            {
                value: 3,
                text: "6-12 months"
            },
            {
                value: 4,
                text: "Over a year"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17 who have had minor, definite, or severe difficulties with emotions, concentration, behavior, or getting along with other people
        visibleIf: "(({SDQIMP1_C} == 2) || ({SDQIMP1_C} == 3) || ({SDQIMP1_C} == 4))", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQIMP3_C",
        title: "The difficulties made me upset or distressed during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Only a little"
            },
            {
                value: 3,
                text: "A medium amount"
            },
            {
                value: 4,
                text: "A great deal"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17 who have had minor, definite, or severe difficulties with emotions, concentration, behavior, or getting along with other people
        visibleIf: "(({SDQIMP1_C} == 2) || ({SDQIMP1_C} == 3) || ({SDQIMP1_C} == 4)))", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQIMP4_C",
        title: "The difficulties have interfered with my home life during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Only a little"
            },
            {
                value: 3,
                text: "A medium amount"
            },
            {
                value: 4,
                text: "A great deal"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17 who have had minor, definite, or severe difficulties with emotions, concentration, behavior, or getting along with other people
        visibleIf: "(({SDQIMP1_C} == 2) || ({SDQIMP1_C} == 3) || ({SDQIMP1_C} == 4))", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQIMP5_C",
        title: "The difficulties have interfered with my friendships during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Only a little"
            },
            {
                value: 3,
                text: "A medium amount"
            },
            {
                value: 4,
                text: "A great deal"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17 who have had minor, definite, or severe difficulties with emotions, concentration, behavior, or getting along with other people
        visibleIf: "(({SDQIMP1_C} == 2) || ({SDQIMP1_C} == 3) || ({SDQIMP1_C} == 4))", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQIMP6_C",
        title: "The difficulties have interfered with my classroom learning during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Only a little"
            },
            {
                value: 3,
                text: "A medium amount"
            },
            {
                value: 4,
                text: "A great deal"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17 who have had minor, definite, or severe difficulties with emotions, concentration, behavior, or getting along with other people
        visibleIf: "(({SDQIMP1_C} == 2) || ({SDQIMP1_C} == 3) || ({SDQIMP1_C} == 4))", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQIMP7_C",
        title: "The difficulties have interfered with my leisure activities during the past six months.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Only a little"
            },
            {
                value: 3,
                text: "A medium amount"
            },
            {
                value: 4,
                text: "A great deal"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17 who have had minor, definite, or severe difficulties with emotions, concentration, behavior, or getting along with other people
        visibleIf: "(({SDQIMP1_C} == 2) || ({SDQIMP1_C} == 3) || ({SDQIMP1_C} == 4))", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    {
        type: "radiogroup",
        name: "SDQIMP8_C",
        title: "The difficulties put a burden on you or the family as a whole.",
        isRequired: false,
        colCount: 1,
        choices: [
            {
                value: 1,
                text: "Not at all"
            },
            {
                value: 2,
                text: "Only a little"
            },
            {
                value: 3,
                text: "A medium amount"
            },
            {
                value: 4,
                text: "A great deal"
            },
            {
                value: 7,
                text: "Refused"
            },
            {
                value: 9,
                text: "Don't Know"
            }
        ],
        hasOther: false,
        otherText: "Other (Please specify)",
        requiredErrorText: "Please select an option.",
        // Universe logic for Sample Children 4-17 who have had minor, definite, or severe difficulties with emotions, concentration, behavior, or getting along with other people
        visibleIf: "(({SDQIMP1_C} == 2) || ({SDQIMP1_C} == 3) || ({SDQIMP1_C} == 4))", // Replace {AGE} with the Survey.js variable representing age
        // Skip logic can be added here based on your survey flow requirements
    },
    

];