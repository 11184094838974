import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useState } from "react";
import { useOutletContext } from 'react-router-dom';

import useSWR from "swr"; 
import Loading from './Loading';

function HouseholdRandomization({ link, householdId }) {

    const [open, setOpen] = useState(false);

    const [ sendNotification ] = useOutletContext();

    const dialogStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    function handleOpen() {
        setOpen(true);
    }

    function handleClose(){
        setOpen(false);
    }

    async function fetcher() {

        const response = await fetch(`${link}/household/randomizer?id=${householdId}`, 
        { 
          credentials: "include", 
          method: "GET" 
        }
        );
        const data = await response.json();

        return { data };

    }

    const { data, isLoading, error, mutate } = useSWR("api/household/randomizer", fetcher, 
        { keepPreviousData: false, revalidateIfStale: false, revalidateOnReconnect: false, revalidateOnMount: false, revalidateOnFocus: false  })
    
    if (isLoading || error) {
        return <Loading />;
    }

    return (
        <>
            <Button onClick={handleOpen} variant={"outlined"} >Randomize</Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={dialogStyle}>
                    <Typography id="household-randomization-modal-title" variant="h6" component="h2">
                        Perform randomization?
                    </Typography>
                    <Typography id="household-randomization-description" sx={{ mt: 2 }}>
                        Are you sure you want to randomize the household?
                        Please make sure you have added all of the household members before proceeding.
                    </Typography>
                    <Button variant={"outlined"} onClick={() => {mutate(); handleClose(); sendNotification("The household has been randomized.")}}>Yes</Button>
                    <Button variant={"outlined"} onClick={handleClose}>No</Button>
                </Box>
            </Modal>
        </>
    );

};

export default HouseholdRandomization;