import Error from "../Error";
import { useLocation } from "react-router-dom"

function DashboardError({ message }) {

    const { state } = useLocation();

    return <Error message={ state.navMessage ? state.navMessage : message } />

};

export default DashboardError;